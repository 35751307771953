export const OnlyNumbers = (input: string): string => input.replace(/[^0-9]/g, '')

export const Commas = (input: string): string => {
  const onlyNumbers = OnlyNumbers(input)
  const number = Number(onlyNumbers)

  return number.toLocaleString()
}

export const decodeHTMLEntities = (str: string) => {
  return str
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&quot;/g, '"')
    .replace(/&#039;/g, "'")
    .replace(/&gt;/g, '>')
    .replace(/&nbsp;/g, ' ')
}
