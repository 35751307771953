import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'
import { computed } from 'vue'

export type FavouriteProductInfo = {
  id: number | null
  slug: string | null
  name: string | null
}

export type FavouriteInfo = {
  favourite_products: FavouriteProductInfo[]
}

const defaultState: FavouriteInfo = {
  favourite_products: []
}

const favouriteStorage = useStorage<FavouriteInfo>('favourites_info', { ...defaultState })
export const useFavouriteStore = defineStore('favourites', () => {
  const reset = () => {
    favouriteStorage.value = { ...defaultState }
  }

  const add = (product: { id: number; name: string; slug: string }) => {
    if (favouriteStorage.value.favourite_products.find((item) => item.id === product.id)) {
      return
    }

    favouriteStorage.value.favourite_products.push({
      id: product.id,
      name: product.name,
      slug: product.slug
    })
  }
  const remove = (id: number) => {
    const target = favouriteStorage.value.favourite_products.find((item) => item.id === id)
    if (target) {
      const index = favouriteStorage.value.favourite_products.indexOf(target)

      if (index > -1) {
        favouriteStorage.value.favourite_products.splice(index, 1)
      }
    }
  }

  const favourites = computed(() => {
    if (favouriteStorage.value.favourite_products) {
      return favouriteStorage.value.favourite_products
    }

    return []
  })

  return {
    reset,
    add,
    remove,
    favourites
  }
})
