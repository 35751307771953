export const DesktopMenu = () => (
  <div class="sk_wrap flex w-full gap-1 space-x-[2px] pt-[5px]">
    <div class="sk_elem !bg-slate-100 p-4">
      <div class="sk_item size-4 !bg-white"></div>
    </div>
    {[...Array(8)].map((_, num) => (
      <div
        class="sk_elem flex grow justify-center gap-x-3 !bg-slate-100 p-4"
        key={num}
      >
        <div class="sk_item h-4 w-3/5 !bg-white"></div>
      </div>
    ))}
    <div class="sk_elem !bg-slate-100 p-4">
      <div class="sk_item size-4 !bg-white"></div>
    </div>
  </div>
)
