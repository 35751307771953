import { useQuery } from '@vue/apollo-composable'
import { graphql } from '@/ts/graphql/__generated__'

export const websiteMenuFragment = graphql(/* GraphQL */ `
  fragment WebsiteMenuInfo on WebsiteMenu {
    name
    menu_json
  }
`)

const websiteMenuQuery = graphql(/* GraphQL */ `
  query getWebsiteMenu($name: String!) {
    websiteMenu(name: $name) {
      ...WebsiteMenuInfo
    }
  }
`)

const websiteTribeMenuQuery = graphql(/* GraphQL */ `
  query getWebsiteTribeMenu($name: String!, $tribe_slug: String!) {
    websiteMenu(name: $name, tribe_slug: $tribe_slug) {
      ...WebsiteMenuInfo
    }
  }
`)

export function getWebsiteMenu(name: string, tribe_slug?: string | null | undefined) {
  if (tribe_slug) {
    return useQuery(websiteTribeMenuQuery, {
      name,
      tribe_slug
    })
  } else {
    return useQuery(websiteMenuQuery, {
      name
    })
  }
}
