import type { Component } from 'vue'

export enum ComponentType {
  Industry = 'Industry',
  Wishlist = 'Wishlist',
  Entry = 'Entry'
}

export type SetStepType = (params: {
  step?: number | null
  stepName?: ComponentType | null
}) => void

export type SyncDataType = {
  businessName: string
  name: string
  businessAddress: string
  phoneNumber: string
  email: string
  description: string
  tribeSlug: string | undefined
}

export type ComponentTypeKeys = keyof typeof ComponentType

export type ComponentOption = {
  comp: Component
  title?: string | null
  subTitle?: string | null
  propsData?: Record<string, unknown>
  on?: Record<string, unknown>
}

export type componentsMapType = { [key in ComponentType]?: ComponentOption }
