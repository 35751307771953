<script setup lang="ts">
import { vOnClickOutside } from '@vueuse/components'
import StoreListResult from '@/ts/components/stores/StoreListResult.vue'
import GoogleAutocomplete from '@/ts/components/google/GoogleAutocomplete.vue'
import { useGoogleSearch } from '@/ts/services/searchStoreService'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const { onSearchBlur, loading, storeSearch, onSearchStores, showResultList, stores } =
  useGoogleSearch()
</script>

<template>
  <div v-on-click-outside="() => onSearchBlur()">
    <div class="flex items-center space-x-2 rounded-lg border-2 border-primary bg-white p-2">
      <FontAwesomeIcon
        class="text-[#555555]"
        :icon="loading ? 'fa-solid fa-sync' : 'fa-solid fa-magnifying-glass'"
        :spin="loading"
      />
      <GoogleAutocomplete
        class="w-full border-0 bg-transparent p-0 text-xs text-mainText placeholder:text-[#b1b7ba] focus:ring-0"
        :placeholder="t('Zip code, city, or state')"
        v-model="storeSearch.searchLatLng"
        v-model:address="storeSearch.searchAddress"
        @change="onSearchStores"
        @focus="showResultList = true"
        @blur="onSearchBlur"
        @onStart="onSearchBlur"
      >
      </GoogleAutocomplete>
    </div>
    <div
      v-if="showResultList && stores"
      class="relative z-20 flex justify-center"
    >
      <StoreListResult
        :items="stores"
        :address="storeSearch.searchAddress"
        :latLng="storeSearch.searchLatLng"
        class="absolute w-[95%]"
        @selected="() => (showResultList = false)"
      >
      </StoreListResult>
    </div>
  </div>
</template>

<style scoped></style>
