import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { provideApolloClient } from '@vue/apollo-composable'
import { storePath } from '@/ts/services/variables/countryVariable'
import { apolloClient } from '@/ts/plugins/apolloClient'
import { useLocationStore } from '@/ts/stores/location'
import { getTribe } from '@/ts/api/tribes'

type NeedCheckKeys = 'store' | 'location' | 'magasin'

type StoreQueryType = {
  [key in NeedCheckKeys]?: string | null
}

export const useStoreSetting = () => {
  const middleware = (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) => {
    const { store = null, location = null, magasin = null } = to.query as StoreQueryType
    const locationStore = useLocationStore()
    const guessStoreSlug = to.path.split('/')[2]
    const hasStoreAndStartStore =
      !locationStore.slug && to.path.startsWith(`/${storePath}/${guessStoreSlug}`) && guessStoreSlug

    if (store || location || magasin || hasStoreAndStartStore) {
      const newStore = store || location || magasin || guessStoreSlug
      const { onResult: onTribeResult, onError: onTribeError } = provideApolloClient(apolloClient)(
        () => getTribe(newStore as string)
      )

      onTribeResult(async ({ loading, data }) => {
        if (!loading) {
          if (data.tribe && data.tribe.slug) {
            locationStore.setLocation(locationStore.mapLocationData(data.tribe))
            const url = new URL(
              `${window.location.protocol}//${window.location.host}${to.fullPath}`
            )
            url.searchParams.delete('store')
            url.searchParams.delete('location')
            url.searchParams.delete('magasin')
            window.location.href = url.toString()
            return
          } else {
            next()
          }
        }
      })

      onTribeError(() => {
        next()
      })
    } else {
      next()
    }
  }

  return {
    middleware
  }
}
