import { useForm } from 'laravel-precognition-vue'

export type SiteSearchFormType = {
  q: string | undefined
  location: string | undefined | null
}

export type SiteSearchResultItemType = {
  name: string
  text: string
  id: string
  model_type: string
  model_id: string
  organisation_id: string
  ranking: string
  type: string
  heading: string
  sub_heading: string
  caption: string
  slug: string
  url: string
}

export function useSiteSearchForm() {
  const form = useForm<SiteSearchFormType>('post', '/api/site-search', {
    q: undefined,
    location: undefined
  })

  return { form }
}
