import { useScriptTag } from '@vueuse/core'
import { SupportCountryName } from '@/ts/stores/countryLimits'

type CountrySetting = {
  [key in SupportCountryName]?: { clientId: string; language?: string; rejectButton?: boolean }
}

const supportCountry = [SupportCountryName.FRANCE, SupportCountryName.UNITED_KINGDOM]
const countrySetting: CountrySetting = {
  [SupportCountryName.FRANCE]: {
    clientId: import.meta.env.VITE_COOKIE_CONSENT_KEY,
    language: 'fr',
    rejectButton: true
  },
  [SupportCountryName.UNITED_KINGDOM]: {
    clientId: import.meta.env.VITE_COOKIE_CONSENT_KEY,
    language: 'en'
  }
}

const handleCookieConsent = async () => {
  try {
    if (window.cookieConsent) {
      const country = import.meta.env.VITE_COUNTRY as SupportCountryName
      const setting = countrySetting[country]

      if (!setting) {
        throw new Error(`Not find country setting. Please check country: ${country}`)
      }

      const cc = new window.cookieConsent(setting)
      cc.run()
    }
  } catch (e) {
    console.error(e)
  }
}

export const loadCookieConsent = () => {
  const { scriptTag, load, unload } = useScriptTag(
    'https://www.eucookie.eu/public/gdpr-cookie-consent.js',
    handleCookieConsent,
    {
      manual: true,
      defer: true
    }
  )

  return {
    scriptTag,
    load,
    unload
  }
}

export const createCookieConsent = async () => {
  const loadCookieConsentScript = loadCookieConsent()

  if (
    import.meta.env.MODE !== 'development' &&
    supportCountry.includes(import.meta.env.VITE_COUNTRY as SupportCountryName)
  ) {
    await loadCookieConsentScript.load()
  }
}
