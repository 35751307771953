<script setup lang="ts">
import { ref } from 'vue'
import DefaultHeader from '@/ts/components/header/DefaultHeader.vue'
import DefaultFooter from '@/ts/components/footer/DefaultFooter.vue'
import { useElementSize } from '@vueuse/core'
import EnquiryDialogForm from '@/ts/components/enquiries/EnquiryDialogForm.vue'

const headerEle = ref(null)
const { height } = useElementSize(headerEle)
</script>

<template>
  <div class="z-0 w-full">
    <DefaultHeader
      ref="headerEle"
      class="z-10"
    />
    <div
      class="flex w-full"
      :style="{ height: `${height}px` }"
    ></div>
    <div class="min-h-screen">
      <slot></slot>
    </div>
    <DefaultFooter />
    <EnquiryDialogForm></EnquiryDialogForm>
  </div>
</template>
