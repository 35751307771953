import { h, Transition, defineComponent } from 'vue'

// Types
import type { PropType } from 'vue'

export function createJavascriptTransition(
  name: string,
  functions: Record<string, unknown>,
  mode = 'in-out'
) {
  return defineComponent({
    name,
    props: {
      mode: {
        type: String as PropType<'in-out' | 'out-in' | 'default'>,
        default: mode
      },
      disabled: Boolean
    },

    setup(props, { slots }) {
      return () => {
        return h(
          Transition,
          {
            name: props.disabled ? '' : name,
            css: !props.disabled,
            ...(props.disabled ? {} : functions)
          },
          slots.default
        )
      }
    }
  })
}
