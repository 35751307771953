import { convertDistance, getDistance } from 'geolib'
import { distanceUnit } from '@/ts/services/variables/countryVariable'

export function distance(
  source: google.maps.LatLngLiteral,
  destination: google.maps.LatLngLiteral
) {
  return (
    Math.round(
      convertDistance(
        getDistance(
          { latitude: source.lat, longitude: source.lng },
          { latitude: Number(destination.lat), longitude: Number(destination.lng) }
        ),
        distanceUnit
      ) * 100
    ) / 100
  )
}
