<script lang="ts" setup>
import { ref, toRef, watch } from 'vue'

const props = withDefaults(defineProps<{ isShow: boolean }>(), {
  isShow: false
})
const isStrat = ref(false)

const isShow = toRef(props, 'isShow')

watch(
  isShow,
  (val) => {
    isStrat.value = val
  },
  { immediate: true }
)
</script>

<template>
  <div
    class="tick"
    :class="{ invisible: !isShow }"
  >
    <div
      class="trigger"
      :class="{ drawn: isStrat }"
    ></div>
    <svg
      version="1.1"
      id="tick"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 37 37"
      style="enable-background: new 0 0 37 37"
      xml:space="preserve"
    >
      <path
        class="circ path"
        style="
          fill: none;
          stroke: #c80a29;
          stroke-width: 3;
          stroke-linejoin: round;
          stroke-miterlimit: 10;
        "
        d="
	M30.5,6.5L30.5,6.5c6.6,6.6,6.6,17.4,0,24l0,0c-6.6,6.6-17.4,6.6-24,0l0,0c-6.6-6.6-6.6-17.4,0-24l0,0C13.1-0.2,23.9-0.2,30.5,6.5z"
      />
      <polyline
        class="tick path"
        style="
          fill: none;
          stroke: #c80a29;
          stroke-width: 3;
          stroke-linejoin: round;
          stroke-miterlimit: 10;
        "
        points="
	11.6,20 15.9,24.2 26.4,13.8 "
      />
    </svg>
  </div>
</template>

<style lang="scss" scoped>
.tick {
  stroke-dasharray: 50;
  stroke-dashoffset: 50;
  transition: stroke-dashoffset 1s 0.5s ease-out;
  @apply h-12 w-12 bg-white rounded-full;
  .drawn + svg .path {
    @apply opacity-100;
    stroke-dashoffset: 0;
  }
}

.circ {
  @apply opacity-0;
  stroke-dasharray: 130;
  stroke-dashoffset: 130;
  transition: all 1s;
}
</style>
