<script lang="ts" setup>
import { computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useBreakPointsStore } from '@/ts/stores/breakPoints'
import { SupportCountryName } from '@/ts/stores/countryLimits'
import DesktopMenu from '@/ts/components/menu/DesktopMenu.vue'
import MobileMenu from '@/ts/components/menu/MobileMenu.vue'
import { useWindowScroll, useImage } from '@vueuse/core'
import TopStripBanner from '@/ts/components/banner/TopStripBanner.vue'
import { useLocationStore } from '@/ts/stores/location'
import StoreLocator from '@/ts/components/stores/StoreLocator.vue'
import { useHomepageContent } from '@/ts/services/websiteContents/homepageContent'
import { useEnquiryStore } from '@/ts/stores/enquiry'
import * as gcsUtils from '@/ts/utils/gcs'
import { useLocalBusinessSchema } from '@/ts/stores/schema/LocalBusiness'
import { useOrganizationSchema } from '@/ts/stores/schema/Organization'
import { useBrandSchema } from '@/ts/stores/schema/Brand'
import { useWebsiteMenuService } from '@/ts/services/websiteMenus/websiteMenuService'
import WhatsAppIcon from '@/assets/images/Icons-whatsapp.svg'

const { t } = useI18n()
const { y } = useWindowScroll()

const BreakPointsStore = useBreakPointsStore()
const homepageContent = useHomepageContent()
const locationStore = useLocationStore()
const dialogStore = useEnquiryStore()
const schemaLocalBusiness = useLocalBusinessSchema()
const schemaBrand = useBrandSchema()
const schemaOrg = useOrganizationSchema()
const { fetch } = useWebsiteMenuService()

enum LOGOS_NAME {
  ZA = 'sar-za-logo.png',
  EN = 'signarama-english.png',
  FR = 'signarama-france.png',
  NEW = 'signarama-new-logo.png',
  YEARS25 = 'signarama25.png',
  AU = 'signarama-AU.png'
}

const LogoPaths = new Map([
  ['DEFAULT', `${gcsUtils.logonPath}/${LOGOS_NAME.EN}`],
  [SupportCountryName.USA, `${gcsUtils.logonPath}/${LOGOS_NAME.NEW}`],
  [SupportCountryName.CANADA, `${gcsUtils.logonPath}/${LOGOS_NAME.NEW}`],
  [SupportCountryName.AUSTRALIA, `${gcsUtils.logonPath}/${LOGOS_NAME.AU}`],
  [SupportCountryName.FRANCE, `${gcsUtils.logonPath}/${LOGOS_NAME.FR}`],
  [SupportCountryName.SOUTH_AFRICA, `${gcsUtils.logonPath}/${LOGOS_NAME.ZA}`]
])

const logoPath = computed(() => {
  return LogoPaths.get(import.meta.env.VITE_COUNTRY) || LogoPaths.get('DEFAULT')!
})

schemaLocalBusiness.image.value = logoPath.value
schemaOrg.logo.value = logoPath.value
schemaOrg.createLD()
schemaBrand.createLD()
fetch(locationStore.slug)

const { isLoading } = useImage({ src: logoPath.value })

const openQuote = () => {
  dialogStore.toggle(true)
}

watch(homepageContent.data, (newVal) => {
  schemaBrand.logo.value = logoPath.value
  schemaBrand.description.value = newVal?.meta_description || ''
})

watch(
  () => locationStore.slug,
  (newVal) => {
    fetch(newVal)
  }
)
</script>

<template>
  <div class="fixed top-0 z-20 mx-auto w-full bg-white p-0 leading-6">
    <TopStripBanner class="transition-all duration-[400ms] ease-[ease]"></TopStripBanner>
    <!-- LOGO and search location -->
    <div class="flex w-full bg-white">
      <div class="container relative mx-auto flex flex-col">
        <div
          class="flex w-full items-end justify-center py-6 transition-all duration-500 ease-out lg:justify-start"
          :class="{
            '!py-2': !BreakPointsStore.smallerThanLg && y > 0,
            '!py-0': BreakPointsStore.smallerThanLg && y > 0,
            'max-h-full': BreakPointsStore.smallerThanLg,
            '!max-h-0': BreakPointsStore.smallerThanLg && y > 0
          }"
        >
          <RouterLink
            class="flex max-w-[350px] flex-none transition-all duration-500 ease-[ease]"
            :class="{
              '!max-w-[120px]': y > 0,
              'min-h-[77px] w-full animate-pulse rounded bg-slate-100': isLoading
            }"
            :to="{ name: 'Home' }"
          >
            <img
              width="350"
              height="68"
              class="h-auto w-full object-contain"
              :src="logoPath"
              alt="Signarama"
            />
          </RouterLink>
          <template v-if="!locationStore.lockedStore">
            <div
              v-show="y > 0"
              class="hidden w-full grow justify-end space-x-4 lg:flex"
            >
              <div
                class="opacity-0 transition-all delay-200 duration-1000 ease-out"
                :class="{ '!opacity-100': y > 5 }"
              >
                <RouterLink
                  class="text-primary hover:underline"
                  :to="{ name: 'Locations' }"
                >
                  <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" />
                  {{ t('Find your local Signarama store') }}
                </RouterLink>
              </div>
            </div>
            <div
              v-show="y <= 0"
              class="hidden w-full grow justify-end space-x-4 lg:flex"
            >
              <div class="flex w-1/2 justify-end text-lg font-semibold leading-5 text-primary">
                <div class="max-w-[150px] text-right">
                  {{ t('Find your local Signarama store') }}
                </div>
              </div>

              <div class="w-1/2 max-w-[400px]">
                <StoreLocator></StoreLocator>
                <div class="text-right">
                  <RouterLink
                    class="hover:text-cyan-700 focus:text-cyan-700 cursor-pointer text-xs font-extralight leading-5 text-stone-400"
                    :to="{ name: 'Locations' }"
                  >
                    {{ t('Click here for a list of all locations') }}
                  </RouterLink>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div
              class="hidden w-full grow gap-1 lg:flex"
              :class="{ 'flex-col items-end': y <= 0, 'items-center justify-end space-x-2': y > 0 }"
            >
              <RouterLink :to="{ name: 'Welcome', params: { slug: locationStore.slug } }">
                <h2 class="text-xl text-primary">
                  <span
                    :class="{ 'animate-[fadeIn_800ms_ease-out_400ms_forwards] opacity-0': y > 0 }"
                  >
                    Signarama {{ locationStore.name }}
                  </span>
                </h2>
              </RouterLink>

              <div class="flex items-center space-x-5">
                <a
                  v-if="locationStore.whatsAppNumberLink && locationStore.whatsAppNumber"
                  class="flex items-center gap-x-1 text-2xl text-primary"
                  :href="locationStore.whatsAppNumberLink"
                  target="_blank"
                >
                  <WhatsAppIcon class="size-6 fill-primary" />
                  {{ locationStore.whatsAppNumber }}
                </a>
                <a
                  class="text-2xl text-primary"
                  :href="`tel:${locationStore.phone}`"
                >
                  <span
                    class="text-xl"
                    v-if="locationStore.whatsAppNumber"
                  >
                    <FontAwesomeIcon icon="fa-solid fa-phone" />
                  </span>
                  {{ locationStore.phone }}
                </a>
                <button
                  @click.prevent="openQuote"
                  class="ml-3 rounded-full bg-primary px-2 py-1.5 text-xl uppercase text-white"
                >
                  {{ t('Get A Quote') }}
                </button>
              </div>
              <RouterLink
                :to="{ name: 'Locations' }"
                class="text-sm text-mainText"
                :class="{ hidden: y > 0 }"
              >
                {{ t('Change location') }}
              </RouterLink>
            </div>
          </template>
        </div>
        <!-- menu -->
        <DesktopMenu class="hidden lg:flex"></DesktopMenu>
        <MobileMenu class="flex lg:hidden"></MobileMenu>
      </div>
    </div>
  </div>
</template>
