import { useForm } from 'laravel-precognition-vue'
import { SupportCountryName } from '@/ts/stores/countryLimits'
import { type FormattedAddressType } from '@/ts/components/google/autoCompleteAddress/format'
import type { UserTrackingData } from '@/ts/routes/middlewares/userTrackingData'
import { useUserTrackingData } from '@/ts/routes/middlewares/userTrackingData'
import collect from 'collect.js'
import { ref } from 'vue'

type File = {
  bucket: string
  path: string
  filename: string
}

export type ThankYouMessageDataType = {
  name: string
  email: string | null | undefined
  telephone: string | null | undefined
  message: string | null | undefined
}

export type ThankYouMessageType = {
  title: string
  heading: string
  text: string
  data: ThankYouMessageDataType
}

export type viewedProduct = {
  id: number
}

export type EnquiryFormType = {
  form_name: string
  first_name: undefined | string
  last_name: undefined | string
  email: undefined | string
  phone: undefined | string
  message: undefined | string
  business_name: undefined | string
  g_recaptcha_token: undefined | string
  tribe_slug: string | undefined | null
  product_type: string | undefined
  searchLatLng: google.maps.LatLngLiteral | null | undefined
  searchAddress: string | undefined
  searchAddressComponents: FormattedAddressType | undefined | null
  RGPD: boolean | undefined
  files: File[]
  products: viewedProduct[]
  tracking_data: Record<string, UserTrackingData> | unknown
}

enum ContactFormNameField {
  US = 'Signarama Inquiry Form',
  AU = 'Signarama Enquiry Form',
  FR = 'Demande Client aux magasins',
  ID = 'signarama-enquiry-form'
}

export const ContactFormName = new Map([
  [SupportCountryName.USA, ContactFormNameField.US],
  [SupportCountryName.CANADA, ContactFormNameField.US],
  [SupportCountryName.AUSTRALIA, ContactFormNameField.AU],
  [SupportCountryName.UNITED_KINGDOM, ContactFormNameField.AU],
  [SupportCountryName.SINGAPORE, ContactFormNameField.AU],
  [SupportCountryName.SOUTH_AFRICA, ContactFormNameField.AU],
  [SupportCountryName.FRANCE, ContactFormNameField.FR],
  [SupportCountryName.INDONESIAN, ContactFormNameField.ID]
])

const trackingData = useUserTrackingData()
const thankYouMessage = ref<null | ThankYouMessageType>(null)

export function useEnquiryForm(formName: string, slug?: string | undefined | null) {
  const form = useForm<EnquiryFormType>('post', '/api/contact-us', {
    form_name: formName,
    first_name: undefined,
    last_name: undefined,
    email: undefined,
    phone: undefined,
    message: undefined,
    business_name: undefined,
    g_recaptcha_token: undefined,
    tribe_slug: slug,
    product_type: undefined,
    searchLatLng: undefined,
    searchAddress: undefined,
    searchAddressComponents: undefined,
    RGPD: undefined,
    files: [],
    products: [],
    tracking_data: collect(Object.fromEntries(trackingData.state.value)).values().all()
  })

  return { form, thankYouMessage }
}
