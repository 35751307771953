<script lang="ts" setup>
import { ref } from 'vue'
const isHover = ref(false)
const changeHover = (hover: boolean) => {
  isHover.value = hover
}
</script>

<template>
  <div
    @mouseover="changeHover(true)"
    @mouseleave="changeHover(false)"
  >
    <slot :isHover="isHover"></slot>
  </div>
</template>
