<script lang="ts" setup>
import { toRefs, reactive, watch, toValue } from 'vue'
import type { MaybeRefOrGetter } from 'vue'
import TickIcon from './../TickIcon.vue'
import { STATIC_SOURCES_URL } from '@/ts/stores/os'
import type { SetStepType } from './../IndustryType'
import { ComponentType } from './../IndustryType'
import { analyticsIns } from '@/ts/services/analytics'

export type ItemType = {
  id: number
  name: string
  slug: string
  photo: string
}

type PropsType = {
  options?: ItemType[]
  wishList?: ItemType[] | MaybeRefOrGetter<ItemType[]>
}

const props = withDefaults(defineProps<{ propsData: PropsType }>(), {
  propsData: () => ({})
})

const emit = defineEmits<{
  (e: 'passValue', value: { key: string; value: ItemType[] }): void
  (e: 'setStep', value: ThisParameterType<SetStepType>): ReturnType<SetStepType>
}>()

const items = reactive<ItemType[]>([])
const { propsData } = toRefs(props)
const selectOption = reactive<ItemType[]>(toValue(propsData.value.wishList) || [])
const inSelectOptions = (element: ItemType) => {
  return selectOption.includes(element)
}
const insertOptions = (element: ItemType) => {
  if (inSelectOptions(element)) {
    const idx = selectOption.findIndex((item) => item.id === element.id)
    selectOption.splice(idx, 1)
  } else {
    selectOption.push(element)
  }
}

const onNext = async () => {
  await analyticsIns.value?.track('Promotion Page Interaction')
  emit('setStep', { stepName: ComponentType.Entry })
}

watch(
  propsData,
  (val) => {
    if (val.options) {
      items.splice(0, items.length)
      items.push(...val.options)
    }
  },
  { immediate: true }
)

watch(selectOption, (val) => {
  emit('passValue', { key: 'wishlist', value: val })
})
</script>

<template>
  <div>
    <slot name="header"></slot>
    <slot name="content">
      <div class="items-wrap">
        <div
          class="item"
          v-for="item in items"
          :key="item.slug"
        >
          <div
            class="content"
            :class="{ active: inSelectOptions(item) }"
            @click="insertOptions(item)"
          >
            <img
              :src="item.photo"
              :alt="item.name"
            />
            <div class="second-warp">
              <span
                class="title"
                :class="{ active: inSelectOptions(item) }"
              >
                {{ item.name }}
              </span>
              <TickIcon
                class="tick"
                :isShow="inSelectOptions(item)"
              ></TickIcon>
            </div>
          </div>
        </div>
      </div>
    </slot>
    <slot name="footer">
      <div class="footer-warp">
        <button
          class="action-btn"
          @click="emit('setStep', { step: 1 })"
        >
          <img
            :src="`${STATIC_SOURCES_URL}/picker/Arrow-dark.png`"
            alt="Arrow-dark"
          />
          <span>Back to previous page</span>
          <div></div>
        </button>
        <button
          class="action-btn next"
          @click="onNext"
        >
          <div></div>
          <span>Step 3: Entry Form</span>
          <img
            :src="`${STATIC_SOURCES_URL}/picker/Arrow-white.png`"
            alt="Arrow-white"
          />
        </button>
      </div>
    </slot>
  </div>
</template>

<style lang="scss" scoped>
.items-wrap {
  @apply grid grid-cols-1 md:grid-cols-2 gap-y-6 gap-x-10 py-10;
  .item {
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
    @apply bg-white rounded-xl overflow-hidden w-full;
    .content {
      @apply flex flex-col items-center justify-center cursor-pointer text-xl rounded-2xl relative;
      .title {
        @apply text-xl;
        &.active {
          @apply text-[#af2b31];
        }
      }
    }
  }
}

.second-warp {
  @apply flex items-center justify-between w-full py-1 px-3;
}

.tick {
  box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
  @apply visible inline-block;
}

.footer-warp {
  @apply flex flex-col md:flex-row gap-y-4 md:gap-y-0 gap-x-6;
  .action-btn {
    box-shadow: -5px 10px 32px -9px rgba(0, 0, 0, 0.75);
    @apply flex w-full text-center bg-white text-xl rounded-xl justify-between items-center px-5 py-4;
    &.next {
      @apply bg-[#e67660] text-white;
    }
  }
}
</style>
