import { createI18n } from 'vue-i18n'
// I18N configuration
import { i18nLocale } from '@/ts/services/variables/countryVariable'
import messages from '@intlify/unplugin-vue-i18n/messages'

// Because project use Single file component i18n(https://vue-i18n.intlify.dev/guide/advanced/sfc.html#single-file-components)
// But i18n block is 'local' scope in component, so the part registered from here will be treated as global then get warnings messages in devtools console.
// In order to make the DX better, set fallbackWarn: false and missingWarn: false to avoid the warning messages.

const instance = createI18n({
  legacy: false,
  locale: i18nLocale,
  fallbackLocale: 'en-US',
  fallbackWarn: false,
  missingWarn: false,
  messages
})

export default instance
export const i18n = instance.global
