import { type RouteRecordRaw, type RouteRecordName } from 'vue-router'

export const getHasComponentsName = (key: string, routes: RouteRecordRaw[], parentHas = false) => {
  const names: RouteRecordName[] = []
  routes.forEach((route) => {
    if (parentHas || (route.components && route.components[key])) {
      const nestedNames = [
        route.name || '',
        ...(route.children && route.children.length > 0
          ? getHasComponentsName(key, route.children, true)
          : [])
      ]
      names.push(...nestedNames)
    }
  })
  return names
}
