/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import specific icons */
import {
  faMagnifyingGlass,
  faHouseChimney,
  faAngleRight,
  faAngleLeft,
  faClose,
  faXmark,
  faBars,
  faRightLong,
  faSync,
  faCircleChevronLeft,
  faCircleChevronRight,
  faPhone,
  faLocationDot,
  faUpload,
  faStar,
  faSpinner,
  faEnvelope
} from '@fortawesome/free-solid-svg-icons'
import { faCommentDots } from '@fortawesome/free-regular-svg-icons'
import {
  faFacebookF,
  faFacebook,
  faLinkedinIn,
  faPinterestP,
  faYoutube,
  faYelp,
  faGoogle,
  faXTwitter,
  faInstagram
} from '@fortawesome/free-brands-svg-icons'

const initIcon = [
  faMagnifyingGlass,
  faHouseChimney,
  faAngleRight,
  faAngleLeft,
  faRightLong,
  faClose,
  faXmark,
  faBars,
  faFacebookF,
  faFacebook,
  faLinkedinIn,
  faPinterestP,
  faGoogle,
  faYelp,
  faXTwitter,
  faYoutube,
  faInstagram,
  faSync,
  faCircleChevronLeft,
  faCircleChevronRight,
  faPhone,
  faLocationDot,
  faUpload,
  faCommentDots,
  faStar,
  faSpinner,
  faEnvelope
]

/* add icons to the library */
library.add(...initIcon)
