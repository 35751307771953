import { load, ReCaptchaInstance } from 'recaptcha-v3'
import { computed, ref } from 'vue'
import { isBot, isPrerender } from '@/ts/services/variables/isbot'

const loaded = ref(false)
const recaptcha = ref<ReCaptchaInstance | null>(null)

export function useReCpatcha() {
  const isLoaded = computed(() => {
    return loaded.value && recaptcha.value !== null
  })

  const recaptchaLoaded = async () => {
    if (isBot || isPrerender) {
      return
    }
    if (isLoaded.value) {
      return
    }
    recaptcha.value = await load(import.meta.env.VITE_RECAPTCHA_SITE_KEY)
    loaded.value = true
  }

  const execute = async (action: string) => {
    if (!isLoaded.value || !recaptcha.value) {
      throw new Error('ReCaptcha is not loaded')
    }

    return await recaptcha.value.execute(action)
  }

  return {
    recaptchaLoaded,
    execute,
    isLoaded
  }
}
