import { ref, computed } from 'vue'
import { useHead } from '@unhead/vue'

// Documentation: https://schema.org/Brand

const description = ref('')
const url = ref(window.location.origin || '')
const logo = ref('')

export const useBrandSchema = () => {
  const jsonLD = computed(() => {
    const localBusinessJsonLD = {
      '@context': 'https://schema.org',
      '@type': 'Brand',
      name: 'Signarama',
      url: url.value,
      logo: logo.value,
      description: description.value
    }

    return [
      {
        hid: 'local-brand-schema',
        type: 'application/ld+json',
        textContent: JSON.stringify(localBusinessJsonLD)
      }
    ]
  })

  const createLD = () => {
    useHead({
      script: () => jsonLD.value
    })
  }

  return {
    jsonLD,
    logo,
    description,
    createLD
  }
}
