<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import debounce from 'lodash.debounce'
import { VExpandTransition } from '@/ts/components/transitions'
import { type SiteSearchResultItemType, useSiteSearchForm } from '@/ts/api/siteSearch'
import { useLocationStore } from '@/ts/stores/location'
import { ref } from 'vue'

defineEmits<{
  (e: 'close'): void
}>()

const { t } = useI18n()
const { form } = useSiteSearchForm()
const locationStore = useLocationStore()
const result = ref<SiteSearchResultItemType[]>([])
const ready = ref(false)

const onQuery = debounce(async () => {
  if (form.q === '' || form.q === undefined) {
    return
  }
  form.location = locationStore.name
  const { data } = (await form.submit()) as Awaited<
    Promise<{ data: { data: { hits: SiteSearchResultItemType[] } } }>
  >
  result.value = data.data.hits
  ready.value = true
}, 400)
</script>

<template>
  <div class="absolute top-0 z-0 flex w-full flex-col space-y-2 bg-[#817273] text-white">
    <div class="mx-auto flex w-full items-center space-x-4 px-5 pb-2 pt-3 md:max-w-6xl">
      <input
        class="grow border-0 border-b border-white bg-transparent p-0 text-2xl focus:border-white focus:ring-0"
        type="text"
        v-model="form.q"
      />
      <button
        class="rounded-[15px] border border-white px-5 py-1 text-sm capitalize leading-6"
        @click.prevent="onQuery"
      >
        {{ t('search') }}
      </button>
      <FontAwesomeIcon
        class="cursor-pointer"
        icon="fa-solid fa-xmark"
        size="xl"
        @click="$emit('close')"
      />
    </div>

    <div
      class="flex max-h-[500px] w-full flex-col overflow-y-auto bg-white px-6 shadow drop-shadow sm:px-12"
    >
      <VExpandTransition>
        <div
          class="transition-all duration-[250ms]"
          v-if="ready"
        >
          <div
            v-for="item in result"
            :key="item.id"
            class="py-3 sm:px-5"
          >
            <a
              class="text-2xl font-light text-primary"
              :href="item.url"
            >
              {{ item.name }}
            </a>
            <p class="text-lg text-[#333333]">
              {{ item.sub_heading }}
            </p>
            <p class="text-sm text-gray-500">
              {{ item.url }}
            </p>
          </div>
          <div
            v-if="result.length === 0"
            class="px-5 py-3"
          >
            <p class="text-lg text-[#333333]">Sorry, no results</p>
          </div>
        </div>
      </VExpandTransition>
    </div>
  </div>
</template>
