import type {
  ComponentInfoResult,
  ComponentContentInfoResult,
  ComponentContentWithMediaResult
} from '@/ts/api/componentTypes'
import collect from 'collect.js'
import {
  getAllImagesByMediaCollectionName,
  getImageByMediaCollectionName,
  type MediaHasImgKeys
} from '@/ts/services/mediaService'
import type { WebsiteContentInfoResult, WebsiteSectionInfoResult } from '@/ts/api/websiteContents'
import type {
  WebsitePageInfoResult,
  WebsitePageContentInfoResult,
  IndustryWithProductWebsitePageResult
} from '@/ts/api/websitePages'
import type { productComponentResult } from '@/ts/api/products'
import type {
  getTribeHomePageResult,
  getTribeLicenseResult,
  tribeContactResult,
  tribeCustomComponentResult,
  tribeTeamMemberResult
} from '@/ts/api/tribes'

export const getValueByName = (
  name: string,
  data:
    | ComponentInfoResult
    | WebsiteSectionInfoResult
    | WebsitePageInfoResult
    | IndustryWithProductWebsitePageResult
    | getTribeHomePageResult
    | getTribeLicenseResult
    | tribeTeamMemberResult
    | tribeCustomComponentResult
    | tribeContactResult
    | productComponentResult
) => {
  if (data.contents) {
    const field = collect<
      | ComponentContentInfoResult
      | ComponentContentWithMediaResult
      | WebsiteContentInfoResult
      | WebsitePageContentInfoResult
    >(data.contents)
      .where('name', name)
      .first()
    return field?.value
  }
  return null
}

export const getImageByName = (
  name: string,
  data:
    | ComponentInfoResult
    | WebsiteSectionInfoResult
    | WebsitePageInfoResult
    | IndustryWithProductWebsitePageResult
    | getTribeHomePageResult
    | tribeTeamMemberResult
    | productComponentResult,
  size: MediaHasImgKeys = 'original_cropped'
) => {
  if (data.contents) {
    const content = collect<
      ComponentContentWithMediaResult | WebsiteContentInfoResult | WebsitePageContentInfoResult
    >(data.contents)
      .where('name', name)
      .first()

    if (content) {
      return getImageByMediaCollectionName('thumbnail', size, content.media_collection)
    }
  }

  return null
}

export const getImagesByName = (
  name: string,
  data:
    | ComponentInfoResult
    | WebsiteSectionInfoResult
    | WebsitePageInfoResult
    | IndustryWithProductWebsitePageResult
    | getTribeHomePageResult
    | tribeTeamMemberResult
    | productComponentResult,
  size: MediaHasImgKeys = 'original_cropped'
) => {
  if (data.contents) {
    return collect<
      ComponentContentWithMediaResult | WebsiteContentInfoResult | WebsitePageContentInfoResult
    >(data.contents)
      .where('name', name)
      .map((content) =>
        getAllImagesByMediaCollectionName('thumbnail', size, content.media_collection)
      )
      .collapse()
      .toArray<string>()
  }

  return null
}
