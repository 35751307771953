import * as StringMethods from './string'

export enum ModifiersMap {
  ONLY_NUMBERS = 'only-numbers',
  COMMAS = 'commas'
}

export const ModifiersMapMethods = {
  [ModifiersMap.ONLY_NUMBERS]: StringMethods.OnlyNumbers,
  [ModifiersMap.COMMAS]: StringMethods.Commas
}
