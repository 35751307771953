<script setup lang="ts">
import { ref } from 'vue'
import {
  type FormattedAddressType,
  getAddressFormatObject
} from '@/ts/components/google/autoCompleteAddress/format'
import { useGoogleMapsApiPromiseLazy } from '@gmap-vue/v3/composables'
import { onClickOutside, useFocus, watchDebounced } from '@vueuse/core'

defineOptions({
  inheritAttrs: false
})

withDefaults(
  defineProps<{
    error?: null | undefined | string
    errorClass?: string
  }>(),
  {
    errorClass: 'text-red-400'
  }
)

const address = defineModel<string | null | undefined>('address', { required: true })
const addressComponents = defineModel<FormattedAddressType | null | undefined>('addressComponents')
const model = defineModel<google.maps.LatLngLiteral | null | undefined>()

const emit = defineEmits<{
  (
    event: 'change',
    value: {
      lat: number
      lng: number
      address: string
      addressComponents: FormattedAddressType
    }
  ): void
  (event: 'onStart'): void
}>()

type PlacePredictionType = {
  mainText: string | undefined | null
  secondaryText: string | undefined | null
  text: string | undefined | null
  place: google.maps.places.Place
  placeId: string | undefined
}
const items = ref<PlacePredictionType[]>([])
const showList = ref(false)
const isLoading = ref(false)
const searchValue = ref<string | null | undefined>(null)
const autocompleteInput = ref<HTMLInputElement | null>(null)
const autocompleteLists = ref<HTMLInputElement | null>(null)
let autocompleteSession: google.maps.places.AutocompleteSessionToken | null = null
let placeInstance: google.maps.PlacesLibrary | null = null
const selectedPlaceItem = ref<PlacePredictionType | null>(null)
const isSetSearchValue = ref(false)
const { focused } = useFocus(autocompleteInput)
const promise = useGoogleMapsApiPromiseLazy()

const focus = () => {
  if (items.value.length > 0) {
    showList.value = true
  }
}

const moveDirection = (direction: 'UP' | 'DOWN') => {
  // not items
  if (items.value.length === 0) return

  const last = items.value[items.value.length - 1]
  const head = items.value[0]

  // only one item
  if (last === head) {
    selectedPlaceItem.value = head
    return
  }

  if (
    selectedPlaceItem.value === null ||
    selectedPlaceItem.value === (direction === 'UP' ? head : last)
  ) {
    selectedPlaceItem.value = direction === 'UP' ? last : head
  } else {
    const count = direction === 'UP' ? -1 : 1
    const index = items.value.findIndex((item) => item.placeId === selectedPlaceItem.value?.placeId)
    selectedPlaceItem.value = items.value[index + count]
  }
}

const keydownEnter = () => {
  const place = selectedPlaceItem.value ?? items.value[0] ?? null

  if (!place) return

  selectPlace(place)
}

const selectPlace = async (place: PlacePredictionType) => {
  if (!place.place) {
    return
  }
  await getPlaceInfo(place)
  showList.value = false
  items.value = []
  isSetSearchValue.value = true
  searchValue.value = place.text
}

const getPlaceInfo = async (place: PlacePredictionType) => {
  try {
    if (place.place) {
      await place.place.fetchFields({
        fields: ['formattedAddress', 'addressComponents', 'location']
      })

      const location = place.place.location!
      const newAddressComponents = getAddressFormatObject(place.place.addressComponents!)
      model.value = {
        lat: location.lat(),
        lng: location.lng()
      }
      address.value = place.place.formattedAddress
      addressComponents.value = newAddressComponents
      emit('change', {
        lat: location.lat(),
        lng: location.lng(),
        address: place.place.formattedAddress!,
        addressComponents: newAddressComponents
      })
      newAutocompleteSession()
    }
  } catch (e) {
    console.error(e)
  }
}

const getLocationName = (place: PlacePredictionType) => {
  return `<span class="main-text">${place.mainText}</span> <span class="searchKeyword">${place.secondaryText || ''}</span>`
}

const searchPlace = async (text: string) => {
  try {
    emit('onStart')
    isLoading.value = true
    if (placeInstance && autocompleteSession && text !== '') {
      const { suggestions } =
        await placeInstance.AutocompleteSuggestion.fetchAutocompleteSuggestions({
          input: text,
          sessionToken: autocompleteSession,
          includedRegionCodes: JSON.parse(import.meta.env.VITE_GOOGLE_AUTOCOMPLETE_COUNTRIES)
        })

      items.value = suggestions
        .map((item) => {
          const placePrediction = item.placePrediction
          if (placePrediction) {
            const place = placePrediction.toPlace()

            return {
              text: placePrediction.text.text,
              mainText: placePrediction.mainText?.text,
              secondaryText: placePrediction.secondaryText?.text,
              place: place,
              placeId: placePrediction.placeId
            }
          }
          return null
        })
        .filter((value) => !!value) as PlacePredictionType[]

      showList.value = true
    }
  } catch (e) {
    console.error(e)
  } finally {
    isLoading.value = false
  }
}

const newAutocompleteSession = () => {
  autocompleteSession = new google.maps.places.AutocompleteSessionToken()
}

onClickOutside(autocompleteLists, () => {
  if (!focused.value) {
    showList.value = false
  }
})

watchDebounced(
  searchValue,
  (value) => {
    if (value && value === '') {
      items.value = []
    }
    if (isSetSearchValue.value) {
      isSetSearchValue.value = false
      return
    }

    searchPlace(value!)
  },
  { debounce: 500 }
)

promise.then(async () => {
  placeInstance = (await google.maps.importLibrary('places')) as google.maps.PlacesLibrary
  newAutocompleteSession()
})
</script>

<template>
  <div class="relative grow">
    <input
      ref="autocompleteInput"
      v-bind="$attrs"
      class="w-full"
      type="text"
      v-model="searchValue"
      @keydown.enter.self="keydownEnter"
      @keydown.down.prevent="moveDirection('DOWN')"
      @keydown.up.prevent="moveDirection('UP')"
      @focus.prevent="focus"
      autocomplete="off"
    />
    <div v-if="showList">
      <div
        class="absolute left-0 top-10 z-30 w-full overflow-hidden rounded-xl border bg-white shadow-xl"
        ref="autocompleteLists"
      >
        <div
          class="flex min-w-60 cursor-pointer items-center space-x-3 border-b px-3 py-2 text-base text-mainText last:border-none hover:bg-gray-100"
          :class="{ 'bg-[#d6e9ff]': selectedPlaceItem?.placeId === place.placeId }"
          v-for="(place, key) in items"
          :key="`${place.placeId}_${key}`"
          @click="selectPlace(place)"
        >
          <font-awesome-icon
            class="text-gray-300"
            icon="fa-solid fa-location-dot"
          />
          <span v-html="getLocationName(place)"></span>
        </div>
      </div>
    </div>
  </div>
  <p
    v-if="error"
    class="mt-2 text-sm"
    :class="errorClass"
  >
    {{ error }}
  </p>
</template>

<style scoped lang="scss">
:deep(.main-text) {
  @apply text-primary;
  .searchKeyword {
    @apply font-bold;
  }
}
</style>
