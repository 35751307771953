<script lang="ts" setup>
import IndustryPicker from './IndustryPicker.vue'
import { usePromotion } from '@/ts/services/promotions/promotion'
import { useLocationStore } from '@/ts/stores/location'
import { watch } from 'vue'
import { analyticsIns } from '@/ts/services/analytics'

const props = defineProps<{ slug: string }>()
// inject location store and get current slug
const locationStore = useLocationStore()

const { pickerOptions, ready, componentMap } = usePromotion(props.slug, locationStore.slug!)

watch(
  () => ready.value,
  async (value) => {
    if (value) {
      setTimeout(async () => {
        await analyticsIns.value?.track('Promotion Page Visit')
      }, 2500)
    }
  }
)
</script>

<template>
  <IndustryPicker
    v-if="ready && componentMap"
    class="py-8"
    :options="pickerOptions"
    :componentMap="componentMap"
  ></IndustryPicker>
  <div
    v-else
    class="sk_wrap relative aspect-square bg-white"
  >
    <div class="sk_item flex h-80 w-full items-center justify-center !bg-slate-200/40"></div>
    <div class="flex flex-col gap-y-2 p-4">
      <div class="sk_item flex h-4 !bg-slate-200/40"></div>
      <div class="sk_item flex h-4 w-4/5 !bg-slate-200/40"></div>
    </div>
    <div class="absolute bottom-0 flex w-full flex-col gap-y-2 p-4">
      <div class="sk_item flex h-4 w-1/5 !bg-slate-200/40"></div>
    </div>
  </div>
</template>
