import { UAParser } from 'ua-parser-js'
import type {
  LocationQueryRaw,
  LocationQueryValue,
  NavigationGuardNext,
  RouteLocationNormalized
} from 'vue-router'
import { breakpointsTailwind, useBreakpoints, useStorage } from '@vueuse/core'
import collect from 'collect.js'
import { computed } from 'vue'

export type UserTrackingData = {
  parameter: string
  value: string
  path: string | null
  session_at: Date
}

type UserTrackingDataInLocalStorage = {
  dataType: string
  value: [[string, UserTrackingData]]
}

const parser = new UAParser()
const breakpoints = useBreakpoints(breakpointsTailwind)
const isDesktop = breakpoints.greaterOrEqual('lg')
const trackingData = new Map<string, UserTrackingData>()

const state = useStorage('tracking', trackingData, localStorage, {
  mergeDefaults: true,
  serializer: {
    read: (value: string) => {
      return new Map(JSON.parse(value, reviver))
    },
    write: (value) => {
      return JSON.stringify(value, replacer)
    }
  }
})

export const useUserTrackingData = () => {
  const middleware = (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) => {
    const date = new Date()

    state.value.set('browser', {
      parameter: 'browser',
      value: parser.getBrowser().name ?? '',
      path: null,
      session_at: date
    })
    state.value.set('device', {
      parameter: 'device',
      value: isDesktop ? 'Desktop' : 'Mobile',
      path: null,
      session_at: date
    })
    state.value.set('operating_system', {
      parameter: 'operating_system',
      value: parser.getOS().name ?? '',
      path: null,
      session_at: date
    })

    collect(to.query)
      .filter((value: LocationQueryValue | LocationQueryValue[], key: string) => {
        return !!(key && value)
      })
      .each((value, key) => {
        state.value.set(`${key}_${value}_${to.path}`, {
          parameter: key?.toString() ?? '',
          value: value?.toString() ?? '',
          path: to.path,
          session_at: date
        })
      })

    next()
  }

  const getUserTrackingDataQuery = computed(() => {
    const data = {} as LocationQueryRaw
    state.value.forEach((value) => {
      data[value.parameter] = value.value
    })
    return data
  })

  return {
    middleware,
    getUserTrackingDataQuery,
    state
  }
}

function replacer(key: undefined | string | number, value: unknown) {
  if (value instanceof Map) {
    return {
      dataType: 'Map',
      value: value ? Array.from(value.entries()) : null
    }
  } else {
    return value
  }
}

function reviver(key: undefined | string | number, value: UserTrackingDataInLocalStorage) {
  if (typeof value === 'object' && value !== null) {
    if (value.dataType === 'Map') {
      return new Map(value.value)
    }
  }
  return value
}
