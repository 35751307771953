import { getWebsiteSection } from '@/ts/api/websiteContents'
import { computed } from 'vue'
import { getValueByName, getImageByName } from '@/ts/services/websiteContentValue'
import { SupportCountryName, useCountryLimitsStore } from '@/ts/stores/countryLimits'

enum FranchiseTextNames {
  US = 'Franchise Text',
  FR = 'Posseder un magasin signarama'
}

const FranchiseTexts = new Map([
  [SupportCountryName.USA, FranchiseTextNames.US],
  [SupportCountryName.FRANCE, FranchiseTextNames.FR]
])

const phones = new Map([
  [SupportCountryName.USA, { text: '800 286 8671', url: 'tel:+18002868671' }],
  [SupportCountryName.CANADA, { text: '800 286 8671', url: 'tel:+18002868671' }],
  [SupportCountryName.UNITED_KINGDOM, { text: '07425 605 022', url: 'tel:+447425605022' }],
  [SupportCountryName.SINGAPORE, { text: '9385 5054', url: 'tel:+6593855054' }],
  [SupportCountryName.SOUTH_AFRICA, { text: '012 285 0412', url: 'tel:0122850412' }],
  [SupportCountryName.AUSTRALIA, { text: '1300 910 322', url: 'tel:+611300910322' }],
  [SupportCountryName.INDONESIAN, { text: '021 539 6771', url: 'tel:+625396771' }]
])

export const useFooterContent = () => {
  const countryLimitsStore = useCountryLimitsStore()
  const { result, loading } = getWebsiteSection('footer')

  const data = computed(() => {
    if (
      !loading.value &&
      result.value &&
      result.value.websiteSections &&
      result.value.websiteSections.length > 0
    ) {
      const section = result.value.websiteSections[0]
      if (section) {
        return {
          phone:
            phones.get(countryLimitsStore.state.country) ?? phones.get(SupportCountryName.USA)!,
          description: getValueByName('A propos de Signarama', section),
          franchise: {
            description: getValueByName(
              FranchiseTexts.get(countryLimitsStore.state.country) ??
                FranchiseTexts.get(SupportCountryName.USA)!,
              section
            ),
            url:
              countryLimitsStore.state.country === SupportCountryName.FRANCE
                ? 'https://www.franchise-de-signaletique.fr/'
                : getValueByName('Own Franchise URL', section),
            image: getImageByName('Franchise Image', section)
          },
          socialLinks: {
            facebook: getValueByName('Facebook URL', section),
            linkedin: getValueByName('LinkedIn URL', section),
            pinterest: getValueByName('Pinterest URL', section),
            youtube: getValueByName('Youtube URL', section),
            instagram: getValueByName('Instagram URL', section)
          }
        }
      }
    }
    return null
  })

  return {
    data
  }
}
