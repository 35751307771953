<script setup lang="ts">
import { computed } from 'vue'
import classnames from 'classnames'
import { useCountryLimitsStore } from '@/ts/stores/countryLimits'
import { useWindowScroll } from '@vueuse/core'
import { useTopStripeBannerService } from '@/ts/services/topStripeBannerService'
import { useLocationStore } from '@/ts/stores/location'
import { useI18n } from 'vue-i18n'
import type { NavigationFailure, RouteLocation } from 'vue-router'

const { y } = useWindowScroll()
const { t } = useI18n()
const { canJoinASignaramaFranchise, state } = useCountryLimitsStore()
const locationStore = useLocationStore()
const topStripeBannerService = useTopStripeBannerService()

const lockStoreLinks = computed(() => {
  const defaultLinks = [
    { name: 'OurWork', label: t('our work') },
    { name: 'Team', label: t('about us') },
    { name: 'Contact', label: t('contact') }
  ]
  if (state.isZA) {
    return defaultLinks
  }

  return [{ name: 'Artwork', label: t('upload artwork') }, ...defaultLinks]
})

const headerStyle = computed(() =>
  classnames({
    'border-b border-gray-200 bg-white': locationStore.lockedStore,
    'bg-primary': !locationStore.lockedStore,
    'max-h-96': y.value === 0,
    'max-h-0': y.value > 0
  })
)

const onNavigation = async (
  navigate: (e?: MouseEvent) => Promise<void | NavigationFailure>,
  route: RouteLocation
) => {
  await navigate()
  if (route.name !== 'Welcome') {
    setTimeout(() => {
      window.scrollTo({
        top: document.getElementById('beginPosition')?.offsetTop,
        behavior: 'smooth'
      })
    }, 1000)
  }
}
</script>

<template>
  <!-- top -->
  <div
    v-if="canJoinASignaramaFranchise"
    class="hidden overflow-hidden text-left text-lg font-normal leading-7 text-white lg:flex"
    :class="headerStyle"
  >
    <!-- nonlock store -->
    <div
      v-if="!locationStore.lockedStore"
      class="container relative mx-auto py-1.5 transition-all duration-500"
      :class="{ 'opacity-30': y > 0 }"
    >
      <a
        :href="topStripeBannerService.topStripBanner.value?.url ?? '#'"
        target="_blank"
        class="hover:text-cyan-700 focus:text-cyan-700 inline-flex cursor-pointer items-center space-x-1 text-left text-lg leading-7 text-white hover:underline"
      >
        <div v-html="topStripeBannerService.topStripBanner.value.message"></div>
        <div class="inline-block size-5">
          <img
            width="20"
            height="20"
            src="@/assets/images/arrow-right-circle.png"
            alt="Learn more"
          />
        </div>
      </a>
    </div>
    <!-- lock store -->
    <div
      v-else
      class="relative w-full py-2"
    >
      <div class="container mx-auto flex justify-end">
        <div
          class="flex items-center"
          v-for="(link, key) in lockStoreLinks"
          :key="key"
        >
          <RouterLink
            :to="{ name: link.name, params: { slug: locationStore.slug } }"
            v-slot="{ href, navigate, route }"
            custom
          >
            <a
              class="px-2 text-base capitalize text-gray-500 hover:text-gray-900"
              :href="href"
              @click.prevent="onNavigation(navigate, route)"
            >
              {{ link.label }}
            </a>
          </RouterLink>
          <span
            v-show="lockStoreLinks.length !== key + 1"
            class="inline-block h-[12px] w-px bg-gray-500"
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
