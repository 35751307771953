import { defineStore } from 'pinia'
import { reactive, readonly, computed } from 'vue'
import { getWebsiteSection } from '@/ts/api/websiteContents'
import type { WebsiteSectionInfoResult } from '@/ts/api/websiteContents'

export const useSiteHeaderConfigStore = defineStore('siteHeader', () => {
  const state = reactive<{
    error: boolean
    loaded: boolean
    data: undefined | null | WebsiteSectionInfoResult
  }>({
    loaded: false,
    data: undefined,
    error: false
  })

  const fetch = () => {
    if (state.loaded) {
      return
    }
    const { onResult, onError } = getWebsiteSection('header')
    onResult((response) => {
      if (!response.loading) {
        state.data =
          response.data.websiteSections && response.data.websiteSections?.length > 0
            ? response.data.websiteSections[0]
            : null
        state.loaded = true
      }
    })

    onError(() => {
      state.error = true
    })
  }

  const isLoaded = computed(() => {
    return state.loaded
  })

  const isError = computed(() => {
    return state.error
  })

  const data = computed(() => state.data)

  return {
    state: readonly(state),
    isLoaded,
    isError,
    data,
    fetch
  }
})
