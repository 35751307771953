import { useSiteHeaderConfigStore } from '@/ts/stores/siteHeaderConfig'
import { computed } from 'vue'
import { getValueByName } from '@/ts/services/websiteContentValue'
import { SupportCountryName, useCountryLimitsStore } from '@/ts/stores/countryLimits'

enum TopStripeUrl {
  US = 'https://signaramafranchise.com/',
  ZA = 'https://www.signfranchise.co.za/',
  AU = 'https://signaramafranchise.com.au/',
  UK = 'https://signaramafranchise.co.uk/',
  SG = 'https://www.signarama.com.sg/about/franchising',
  CA = 'https://signaramafranchise.ca/',
  ID = 'https://signfranchise.co.id/'
}

export const TopStripeUrlMap = new Map([
  [SupportCountryName.USA, TopStripeUrl.US],
  [SupportCountryName.SOUTH_AFRICA, TopStripeUrl.ZA],
  [SupportCountryName.AUSTRALIA, TopStripeUrl.AU],
  [SupportCountryName.UNITED_KINGDOM, TopStripeUrl.UK],
  [SupportCountryName.SINGAPORE, TopStripeUrl.SG],
  [SupportCountryName.CANADA, TopStripeUrl.CA],
  [SupportCountryName.INDONESIAN, TopStripeUrl.ID]
])

const TopStripeMessage = new Map([
  [
    SupportCountryName.USA,
    'Join our winning team and <strong>OWN YOUR OWN SIGNARAMA FRANCHISE!</strong> Learn more'
  ],
  [
    SupportCountryName.FRANCE,
    'Rejoignez le plus grand réseau mondial en <strong>devenant franchisé Signarama</strong> ! En savoir plus'
  ],
  [
    SupportCountryName.AUSTRALIA,
    'Join our winning team and <strong>OWN A SIGNARAMA FRANCHISE!</strong> Learn more'
  ],
  [
    SupportCountryName.INDONESIAN,
    'Bergabunglah dengan tim kami dan <strong>MILIKI FRANCHISE SIGNARAMA!</strong> Pelajari Selengkapnya'
  ]
])

export const useTopStripeBannerService = () => {
  const store = useSiteHeaderConfigStore()
  const countryLimitsStore = useCountryLimitsStore()

  const topStripBanner = computed(() => {
    if (store.data) {
      const message = getValueByName('Top Strip Message', store.data)
      const url = getValueByName('Top Strip Link', store.data)
      if (message && url) {
        return {
          message: getValueByName('Top Strip Message', store.data),
          url: getValueByName('Top Strip Link', store.data)
        }
      }
    }

    // default message
    return {
      message: TopStripeMessage.has(countryLimitsStore.state.country)
        ? TopStripeMessage.get(countryLimitsStore.state.country)
        : TopStripeMessage.get(SupportCountryName.AUSTRALIA),
      url: TopStripeUrlMap.has(countryLimitsStore.state.country)
        ? TopStripeUrlMap.get(countryLimitsStore.state.country)
        : null
    }
  })

  return {
    topStripBanner
  }
}
