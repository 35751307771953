<script lang="ts" setup>
import { computed, ref } from 'vue'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import SearchBar from '@/ts/components/menu/SearchBar.vue'
import { useWebsiteMenuService } from '@/ts/services/websiteMenus/websiteMenuService'
import { useLocationStore } from '@/ts/stores/location'
import { VExpandTransition } from '@/ts/components/transitions'
import { useEnquiryStore } from '@/ts/stores/enquiry'
import { useCountryLimitsStore } from '@/ts/stores/countryLimits'
import { useI18n } from 'vue-i18n'
import WhatsAppIcon from '@/assets/images/Icons-whatsapp.svg'

const { t } = useI18n()
const locationStore = useLocationStore()
const { data } = useWebsiteMenuService()
const dialogStore = useEnquiryStore()
const mainMenus = computed(() => data.value)
const showSearchBar = ref(false)
const showMenu = ref(false)
const search = ref('')
const { state } = useCountryLimitsStore()

const openQuote = () => {
  dialogStore.toggle(true)
}

const locationLinks = computed(() => {
  if (state.isZA) {
    return [
      { name: 'Welcome', label: t('home') },
      { name: 'OurWork', label: t('our work') },
      { name: 'Team', label: t('about us') },
      { name: 'Contact', label: t('contact') }
    ]
  }
  return [
    { name: 'Welcome', label: t('home') },
    { name: 'Artwork', label: t('artwork upload') },
    { name: 'OurWork', label: t('our work') },
    { name: 'Team', label: t('about us') },
    { name: 'Contact', label: t('contact') }
  ]
})
</script>

<template>
  <div class="relative flex items-center bg-primary text-white">
    <div
      class="flex-none cursor-pointer px-7 py-4"
      @click="showMenu = !showMenu"
    >
      <FontAwesomeIcon
        v-if="!showMenu"
        icon="fa-solid fa-bars"
        size="xl"
      />
      <FontAwesomeIcon
        v-else
        class="px-[1.5px]"
        icon="fa-solid fa-xmark"
        size="xl"
      />
    </div>
    <div class="grow text-center">
      <div
        v-if="locationStore.lockedStore"
        class="flex justify-center space-x-4 sm:space-x-8"
      >
        <a
          v-if="locationStore.whatsAppNumberLink"
          :href="locationStore.whatsAppNumberLink"
          target="_blank"
          class="flex items-center gap-x-1"
        >
          <WhatsAppIcon class="size-6 fill-white" />
          {{ locationStore.whatsAppNumber }}
        </a>
        <a :href="`tel:${locationStore.phone}`">
          <FontAwesomeIcon icon="fa-solid fa-phone" />
          {{ locationStore.phone }}
        </a>
      </div>

      <RouterLink
        v-else
        class="text-2xl underline"
        :to="{ name: 'Locations' }"
      >
        {{ t('Find Your Store') }}
      </RouterLink>
    </div>
    <div
      class="flex-none cursor-pointer px-7 py-4"
      @click="showSearchBar = !showSearchBar"
    >
      <FontAwesomeIcon
        icon="fa-solid fa-magnifying-glass"
        size="xl"
      />
    </div>
    <VExpandTransition>
      <!-- Menu -->
      <div
        v-if="showMenu"
        class="absolute top-full z-[1] flex w-full flex-col border-t-2 border-white bg-white transition-all duration-500"
      >
        <div class="flex justify-center space-x-4 bg-primary py-3">
          <!-- INQUIRE NOW -->
          <button
            class="inline-flex items-center justify-center space-x-1"
            @click="openQuote"
          >
            <span class="text-xl uppercase text-[#ffaea9]">
              {{ t('enquire now') }}
            </span>
            <div>
              <img
                class="h-[20px]"
                src="@/assets/images/mail.png"
                :alt="t('enquire now')"
              />
            </div>
          </button>
          <!-- FIND US -->
          <a
            class="inline-flex items-center space-x-1 text-xl text-[#ffaea9]"
            v-if="locationStore.lockedStore"
            :href="locationStore.googleMapUrl || ''"
            target="_blank"
          >
            <span class="uppercase">
              {{ t('find us') }}
            </span>
            <div>
              <img
                class="h-[20px]"
                src="@/assets/images/pin.png"
                :alt="t('find us')"
              />
            </div>
          </a>
        </div>
        <div
          v-if="locationStore.lockedStore"
          class="ignore-scroll-width flex touch-pan-x space-x-1 overflow-x-auto scroll-smooth whitespace-nowrap bg-[#9a1b1b] py-2 text-white lg:whitespace-normal"
        >
          <router-link
            v-for="(link, key) in locationLinks"
            :key="key"
            :to="{ name: link.name, params: { slug: locationStore.slug } }"
            v-slot="{ href, navigate }"
            custom
          >
            <a
              class="px-2 py-1 capitalize"
              :href="href"
              @click.stop="navigate"
              ><span>{{ link.label }}</span></a
            >
            <span class="flex items-center">
              <span
                v-show="key !== locationLinks.length - 1"
                class="inline-block h-4 w-px bg-white"
              ></span>
            </span>
          </router-link>
        </div>
        <div class="flex max-h-[50vh] flex-col overflow-y-auto">
          <div class="p-6 text-[1.375rem] font-light uppercase text-[#7E7373]">PRODUCTS</div>
          <div v-if="mainMenus">
            <Disclosure
              v-for="(item, key) in mainMenus.children"
              as="div"
              class="w-full border-b border-[#0000000d] px-6 py-4 first:pt-0 last:border-0"
              :key="key"
            >
              <DisclosureButton
                v-if="item.children.length > 0"
                class="space-x-3 text-left text-xl font-medium leading-[1.1] text-black"
              >
                <FontAwesomeIcon
                  icon="fa-solid fa-angle-right"
                  size="lg"
                />
                <span>{{ item.label }}</span>
              </DisclosureButton>
              <a
                v-else
                class="space-x-3 text-left text-xl font-medium leading-[1.1] text-black"
                :href="item.path"
                :target="item.url_target"
              >
                <span>{{ item.label }}</span>
              </a>
              <VExpandTransition>
                <DisclosurePanel class="px-7 py-2 text-black transition-all duration-500">
                  <a
                    v-for="(subItem, subKey) in item.children"
                    class="flex cursor-pointer items-center justify-between py-3 text-lg"
                    :key="subKey"
                    :href="subItem.path"
                  >
                    {{ subItem.label }}
                    <FontAwesomeIcon
                      class="text-xl"
                      icon="fa-solid fa-angle-right"
                    />
                  </a>
                </DisclosurePanel>
              </VExpandTransition>
            </Disclosure>
          </div>
        </div>
      </div>
    </VExpandTransition>
    <!-- Search Bar -->
    <SearchBar
      class="top-full"
      v-if="showSearchBar"
      :search="search"
      @close="showSearchBar = false"
    ></SearchBar>
  </div>
</template>
