<script setup lang="ts">
import BaseEnquiryForm from '@/ts/components/enquiries/form/dialog/BaseEnquiryForm.vue'
import type { EnquiryFormType } from '@/ts/api/enquiries'
import { toRefs } from 'vue'
import type { Form } from 'laravel-precognition-vue/dist/types'
import GoogleAutocomplete from '@/ts/components/google/GoogleAutocomplete.vue'
import { useI18n } from 'vue-i18n'

const rootProps = defineProps<{
  form: EnquiryFormType & Form<EnquiryFormType>
}>()
const { form } = toRefs(rootProps)
const { t } = useI18n()
</script>

<template>
  <BaseEnquiryForm :form="form">
    <template v-slot:default="{ form }">
      <div class="col-span-2">
        <label
          for="google_autocomplete"
          class="block text-sm font-bold leading-normal text-gray-900"
        >
          {{ t('Business Address') }}
        </label>
        <div class="mt-2">
          <GoogleAutocomplete
            id="google_autocomplete"
            v-model="form.searchLatLng"
            v-model:address="form.searchAddress"
            v-model:addressComponents="form.searchAddressComponents"
            class="block w-full rounded-md border-[#ccd0d2] py-1.5 text-base text-gray-900 shadow-sm focus:border-[#98cbe8] sm:text-sm sm:leading-6"
            :error="form.errors.searchAddress"
          >
          </GoogleAutocomplete>
        </div>
      </div>
    </template>
    <template v-slot:bottom="{ form }">
      <div class="col-span-2">
        <div>
          <input
            type="checkbox"
            @change="form.validate('RGPD')"
            v-model="form.RGPD"
          />
          En soumettant ce formulaire, j'accepte que les informations saisies soient traitées par la
          Franchise Signarama dans le cadre de ma demande de contact et de la relation commerciale
          qui peut en découler. En savoir plus en consultant notre politique de confidentialité.
          <a
            class="text-primary"
            href="/about/politique-de-confidentialite"
          >
            politique de confidentialité
          </a>
        </div>
        <p
          v-if="form.errors.RGPD"
          class="my-2 text-sm text-red-400"
        >
          {{ form.errors.RGPD }}
        </p>
      </div>
    </template>
  </BaseEnquiryForm>
</template>

<style scoped></style>
