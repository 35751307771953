import { ref, reactive } from 'vue'
import { type getBaseTribeInfoResult, searchStores } from '@/ts/api/tribes'
import { useDebounceFn } from '@vueuse/core'
import type { SearchStoresVariables } from '@/ts/api/tribes'
import { storeType } from '@/ts/services/variables/countryVariable'

export type searchConditionType = {
  address: string
  lat: number
  lng: number
}

export const useGoogleSearch = () => {
  const showResultList = ref(false)
  const stores = ref<null | getBaseTribeInfoResult[]>(null)
  const clearStores = () => {
    stores.value = null
  }
  const storeSearch = reactive<{ searchLatLng: undefined; searchAddress: undefined }>({
    searchLatLng: undefined,
    searchAddress: undefined
  })
  const searchLocationOptions = ref<SearchStoresVariables>({
    tribe_type: storeType || '',
    address: '',
    lat: 0,
    lng: 0,
    radius: 80
  })

  const { load, onResult, loading } = searchStores(searchLocationOptions)

  const onSearchStores = ({ address, lat, lng }: searchConditionType) => {
    Object.assign(searchLocationOptions.value, {
      address,
      lat,
      lng
    })
    load()
  }

  onResult((response) => {
    if (response.data?.searchStores) {
      stores.value = response.data.searchStores.slice(0, 10)
      showResultList.value = true
    }
  })

  const onSearchBlur = useDebounceFn(() => {
    showResultList.value = false
  }, 80)

  return {
    showResultList,
    stores,
    storeSearch,
    searchLocationOptions,
    loading,
    clearStores,
    onSearchStores,
    onSearchBlur
  }
}
