export type PipelineFunction<T> = (input: T) => T

export const runPipeline = <T>(input: T, functions: PipelineFunction<T>[]): T => {
  let result = input

  for (const func of functions) {
    result = func(result)
  }

  return result
}
