<script lang="ts" setup>
import {
  ref,
  reactive,
  watch,
  toRefs,
  unref,
  toValue,
  computed,
  onMounted,
  type ComputedRef,
  nextTick
} from 'vue'
import type { Ref, MaybeRefOrGetter } from 'vue'
import EditIcon from './../icon/edit'
import FormInput from './../components/FormInput.vue'
import { type SyncDataType, type SetStepType } from './../IndustryType'
import { ComponentType } from './../IndustryType'
import type { ItemType as WishListItemType } from './../cards/WishlistCard.vue'
import GoogleAutocompleteInput from './../components/GoogleAutocompleteInput.vue'
import type { getBaseTribeInfoResult } from '@/ts/api/tribes'
import { analyticsIns } from '@/ts/services/analytics'
import { useLocationStore } from '@/ts/stores/location'
import FormTextarea from '@/ts/components/picker/industryPicker/components/FormTextarea.vue'

type PropsType = {
  storeOptions?: { label: string; value: string }[]
  industry?: string | Ref<string>
  wishList?: WishListItemType[] | MaybeRefOrGetter<WishListItemType[]>
  userDetails?: SyncDataType
  searchStores?: {
    storeSearch: {
      searchAddress?: string
      searchLatLng?: google.maps.LatLngLiteral
    }
    stores: getBaseTribeInfoResult[]
    onSearchStores: () => void
  }
  loading?: ComputedRef<boolean>
  errors?: ComputedRef<Record<string, string>>
}

const props = withDefaults(
  defineProps<{
    propsData: PropsType
  }>(),
  {
    propsData: () => ({})
  }
)

const emit = defineEmits<{
  (e: 'passValue', value: { key: string; value: SyncDataType }): void
  (e: 'setStep', value: ThisParameterType<SetStepType>): ReturnType<SetStepType>
  (e: 'submitForm'): void
}>()

const locationStore = useLocationStore()
const industryVal = ref('')
const wishListValue = ref<WishListItemType[]>([])
const { propsData } = toRefs(props)
const syncData = reactive<SyncDataType>({
  businessName: '',
  name: '',
  businessAddress: '',
  phoneNumber: '',
  email: '',
  description: '',
  tribeSlug: undefined
})
const noSubmitYet = ref(false)
const termsAccepted = ref(false)
const selectStoreOption = ref<{ label: string; value: string }[]>([])
const searchValue = ref('')

const { stores, storeSearch, onSearchStores } = toRefs(props.propsData.searchStores!)

const disabledSubmit = computed(() => props.propsData.loading?.value)

const storeName = computed(() => {
  if (syncData.tribeSlug) {
    if (selectStoreOption.value && selectStoreOption.value.length > 0) {
      const store = selectStoreOption.value.find((option) => option.value === syncData.tribeSlug)
      return store ? store.label : 'No Store'
    }
  }

  return 'No Store'
})

const onSubmit = async () => {
  await analyticsIns.value?.track('Promotion Form Submission')
  if (!storeSearch.value.searchAddress) {
    Object.assign(syncData, {
      businessAddress: searchValue.value
    })
  }

  await nextTick(() => {
    noSubmitYet.value = true
    emit('submitForm')
  })
}

watch(syncData, (val) => {
  emit('passValue', { key: 'userDetails', value: val })
})

watch(
  () => stores.value,
  (val) => {
    Object.assign(syncData, {
      businessAddress: storeSearch.value.searchAddress
    })
    if (!locationStore.slug) {
      Object.assign(syncData, {
        tribeSlug: val && val.length > 0 ? val[0].slug : undefined
      })
    }
  }
)

watch(
  propsData,
  (val) => {
    if (val.storeOptions) {
      selectStoreOption.value.splice(0, selectStoreOption.value.length, ...val.storeOptions)
    }
    if (val.industry) {
      industryVal.value = unref(val.industry)
    }

    if (val.wishList) {
      wishListValue.value.splice(0, wishListValue.value.length, ...toValue(val.wishList))
    }
  },
  { immediate: true }
)

onMounted(() => {
  syncData.tribeSlug = props.propsData.userDetails?.tribeSlug
})
</script>

<template>
  <div>
    <slot name="header"></slot>
    <slot name="content">
      <div class="items-wrap">
        <h4 class="page-title">
          This is your <span class="highlight">DREAM MAKEOVER</span> summary:
        </h4>
        <!-- step 1 -->
        <div class="step-item">
          <div class="col-item first"><span class="num">1</span> Industry:</div>
          <div class="col-item content grow-col">{{ industryVal }}</div>
          <div class="col-item">
            <button
              class="edit-btn"
              @click="emit('setStep', { stepName: ComponentType.Industry })"
            >
              EDIT
              <span class="icon">
                <EditIcon></EditIcon>
              </span>
            </button>
          </div>
        </div>
        <!-- step 2 -->
        <div class="step-item">
          <div class="col-item first"><span class="num">2</span> Wishlist:</div>
          <div class="col-item content grow-col">
            {{ wishListValue.map((value) => value.name).join(', ') }}
          </div>
          <div class="col-item">
            <button
              class="edit-btn"
              @click="emit('setStep', { stepName: ComponentType.Wishlist })"
            >
              EDIT
              <span class="icon">
                <EditIcon></EditIcon>
              </span>
            </button>
          </div>
        </div>
        <!-- step 3 -->
        <div class="step-item">
          <div class="col-item first"><span class="num">3</span> Your Details:</div>
          <div class="col-item content grow-col"></div>
          <div class="col-item"></div>
        </div>
        <!-- form -->
        <div class="form-wrap lose">
          <div class="form-row top-block">
            <div class="info-store-wrap">
              <FormInput
                class="form-general"
                label="Business Name"
                v-model="syncData.businessName"
                required
                :error="props.propsData.errors!.value['userDetails.businessName']"
              ></FormInput>
              <FormInput
                class="form-general"
                label="Your Name"
                v-model="syncData.name"
                :error="props.propsData.errors!.value['userDetails.name']"
                required
              ></FormInput>
              <GoogleAutocompleteInput
                class="form-general"
                label="Your Business Address"
                v-model="storeSearch.searchLatLng"
                v-model:address="storeSearch.searchAddress"
                v-model:searchValue="searchValue"
                :error="props.propsData.errors!.value['userDetails.name']"
                @change="onSearchStores"
              ></GoogleAutocompleteInput>
              <FormInput
                class="form-general"
                label="Phone Number"
                v-model="syncData.phoneNumber"
                :error="props.propsData.errors!.value['userDetails.phoneNumber']"
                required
              ></FormInput>
              <FormInput
                class="form-general"
                label="Email Address"
                v-model="syncData.email"
                :error="props.propsData.errors!.value['userDetails.email']"
                required
              ></FormInput>
            </div>
            <!-- store block -->
            <div class="info-store-wrap">
              <div class="store-wrap">
                <h2>Your closest Signarama store is:</h2>
                <div class="title">{{ storeName || 'No Store' }}</div>
                <div class="select-store-wrap">
                  <div>
                    <button class="btn">Select a different store</button>
                  </div>
                  <div>
                    <select
                      id="location"
                      class="select-default"
                      v-model="syncData.tribeSlug"
                    >
                      <option
                        v-for="option in selectStoreOption"
                        :key="option.value"
                        :value="option.value"
                      >
                        {{ option.label }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <p
                v-if="props.propsData.errors!.value['userDetails.tribeSlug']"
                class="input-error-message"
              >
                {{ props.propsData.errors!.value['userDetails.tribeSlug'] }}
              </p>
            </div>
          </div>
          <div class="">
            <FormTextarea
              class="form-general"
              :label="'In a sentence or two, please tell us why you need a signage makeover'"
              v-model="syncData.description"
              :error="props.propsData.errors!.value['userDetails.description']"
            ></FormTextarea>
            <div>The winner will be based on the best response from all completed entries.</div>
          </div>
          <div class="termsAccepted-wrap">
            <div class="terms">
              <input
                type="checkbox"
                id="termsCheckbox"
                v-model="termsAccepted"
              />
              <label for="termsCheckbox">Yes, I agree to the terms and conditions</label>
            </div>
            <p
              v-if="noSubmitYet && !termsAccepted"
              class="input-error-message"
            >
              Please accept the terms and conditions
            </p>
          </div>

          <div class="submit-wrap">
            <a
              class="link"
              href="#"
            >
              Competition Terms and Conditions
            </a>
            <button
              class="action-btn"
              :disabled="disabledSubmit"
              @click="onSubmit"
            >
              Submit my entry!
            </button>
          </div>
        </div>
      </div>
    </slot>
    <slot name="footer"></slot>
  </div>
</template>

<style lang="scss" scoped>
.items-wrap {
  @apply py-6;
}

.page-title {
  @apply font-bold text-lg mb-6;
  .highlight {
    @apply text-[#c80a29];
  }
}

.step-item {
  @apply text-xl flex w-full gap-x-4 py-5 flex-wrap md:flex-nowrap;
  .col-item {
    @apply flex items-center;
    &.first {
      @apply w-full md:w-[200px] gap-x-2 items-center;
    }

    &.content {
      @apply text-[#c80a29] w-full  md:w-full py-3 md:py-0;
    }

    &.grow-col {
      @apply grow;
    }
  }

  .num {
    @apply pt-1 w-8 h-8 font-bold text-sm leading-6 text-center text-white bg-[#c80a29] rounded-full;
  }

  .edit-btn {
    @apply flex items-center gap-x-2 bg-white text-base px-3 py-2 rounded-md;
    .icon {
      @apply w-4 h-4;
    }
  }
}

.form-wrap {
  @apply flex flex-col mt-9;
  .form-row {
    @apply flex;
    &.top-block {
      @apply gap-x-8 flex-col lg:flex-row;
    }
  }

  .info-store-wrap {
    @apply w-full lg:w-1/2;
  }

  &.lose {
    @apply px-8;
  }
}

.form-general {
  @apply mb-3;
}

.termsAccepted-wrap {
  @apply flex flex-col text-sm font-bold py-5 md:py-12;
  .terms {
    @apply flex items-center justify-center gap-x-2;
  }
  .input-error-message {
    @apply text-center;
  }
}

.submit-wrap {
  @apply flex flex-col items-center justify-center gap-x-4 gap-y-4 py-5 md:py-0;
  .link {
    @apply text-sm text-[#c80a29];
  }

  .action-btn {
    box-shadow: -5px 10px 32px -9px rgba(0, 0, 0, 0.75);
    @apply flex text-center text-white bg-[#c80a29] text-xl rounded-xl justify-between items-center px-16 py-6;
    &:disabled {
      @apply bg-[#999];
    }
  }
}

.store-wrap {
  @apply bg-[#c80a29] px-4 py-6 rounded-xl text-white;
  .title {
    @apply text-xl font-bold;
  }
}

.select-store-wrap {
  @apply mt-16;
  .btn {
    @apply text-sm;
  }
}

.select-default {
  @apply mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset sm:text-sm sm:leading-6;
}

.input-error-message {
  @apply mt-1 text-sm text-red-400;
}
</style>
