<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script setup lang="ts">
import { runPipeline } from '@/ts/utils/pipeline'
import type { PipelineFunction } from '@/ts/utils/pipeline'
import { ModifiersMap, ModifiersMapMethods } from '@/ts/utils/ModifiersMap'

type Props = {
  label?: string
  id: string
  type?: string
  labelClass?: string
  error?: null | undefined | string
  errorClass?: string
}

const props = withDefaults(defineProps<Props>(), {
  type: 'text',
  labelClass: 'text-gray-900',
  errorClass: 'text-red-400'
})

const emit = defineEmits<{
  (event: 'change-value'): void
}>()

defineOptions({
  inheritAttrs: false
})

const pipelineFunctions: PipelineFunction<any>[] = []

const [model, modelModifiers] = defineModel({
  set(value) {
    if (pipelineFunctions.length > 0) {
      return runPipeline(value, pipelineFunctions)
    }

    return value
  }
})

// for loop check modelModifiers, if it is not empty, then check key value is true, then get the function from ModifiersMapMethods, and push it to pipelineFunctions
for (const [key, value] of Object.entries(modelModifiers)) {
  if (value) {
    const func = ModifiersMapMethods[key as ModifiersMap]
    if (func) {
      pipelineFunctions.push(func)
    }
  }
}

const onInput = (event: Event) => {
  const el = event.target as HTMLInputElement
  if (props.type.toLowerCase() === 'number') {
    model.value = Number(el?.value)
    return
  }
  model.value = el?.value
}
</script>

<template>
  <div class="w-full">
    <label
      :for="id"
      :class="[labelClass, 'block text-sm font-bold leading-normal']"
    >
      <slot name="label">
        {{ label }}
      </slot>
    </label>
    <div class="mt-2">
      <input
        :id="id"
        v-bind="$attrs"
        :type="type"
        class="block w-full rounded-md border-[#ccd0d2] py-1.5 text-base text-gray-900 shadow-sm focus:border-[#98cbe8] sm:text-sm sm:leading-6"
        :value="modelValue"
        @change="emit('change-value')"
        @input="onInput"
      />
    </div>
    <p
      v-if="error"
      class="mt-2 text-sm"
      :class="errorClass"
    >
      {{ error }}
    </p>
  </div>
</template>

<style scoped></style>
