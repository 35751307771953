import { defineStore } from 'pinia'
import { reactive, readonly, computed } from 'vue'

export enum SupportCountryName {
  USA = 'US',
  CANADA = 'CA',
  UNITED_KINGDOM = 'UK',
  AUSTRALIA = 'AU',
  SINGAPORE = 'SG',
  SOUTH_AFRICA = 'ZA',
  FRANCE = 'FR',
  INDONESIAN = 'ID'
}

export const useCountryLimitsStore = defineStore('countryLimits', () => {
  const COUNTRY = import.meta.env.VITE_COUNTRY as SupportCountryName

  const state = reactive({
    country: COUNTRY,
    isUSA: COUNTRY === SupportCountryName.USA,
    isCA: COUNTRY === SupportCountryName.CANADA,
    isUK: COUNTRY === SupportCountryName.UNITED_KINGDOM,
    isAU: COUNTRY === SupportCountryName.AUSTRALIA,
    isFR: COUNTRY === SupportCountryName.FRANCE,
    isSG: COUNTRY === SupportCountryName.SINGAPORE,
    isZA: COUNTRY === SupportCountryName.SOUTH_AFRICA,
    isID: COUNTRY === SupportCountryName.INDONESIAN
  })

  const canJoinASignaramaFranchise = computed(() => {
    return (
      state.isUSA ||
      state.isCA ||
      state.isUK ||
      state.isAU ||
      state.isSG ||
      state.isZA ||
      state.isFR ||
      state.isID
    )
  })

  return {
    state: readonly(state),
    canJoinASignaramaFranchise
  }
})
