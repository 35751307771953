import { pathToRegexp } from 'path-to-regexp'
import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

export const useWebsiteRedirect = () => {
  const middleware = (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) => {
    if (window.websiteRedirects) {
      const redirect = window.websiteRedirects.find((redirect) => {
        const regexp = pathToRegexp(new RegExp(redirect.from))
        return regexp.exec(to.path)
      })
      if (redirect) {
        next(redirect.to)
        return
      }
    }

    next()
  }

  return {
    middleware
  }
}
