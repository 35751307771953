import { useLazyQuery, useQuery } from '@vue/apollo-composable'
import { graphql } from '@/ts/graphql/__generated__'
import type { Ref } from 'vue'
import type { ResultOf, VariablesOf } from '@graphql-typed-document-node/core'

export type SearchStoresVariables = VariablesOf<typeof searchStoresQuery>
export type tribesQueryVariables = VariablesOf<typeof tribesQuery>
export type getTribeInfoResult = ResultOf<typeof tribeFragment>
export type getBaseTribeInfoResult = ResultOf<typeof baseTribeFragment>
export type getTribeLicenseResult = ResultOf<typeof tribeLicenseFragment>
export type getTribeHomePageResult = ResultOf<typeof tribeHomePageFragment>
export type tribeTeamMemberResult = ResultOf<typeof tribeTeamMembersFragment>
export type tribeCustomComponentResult = ResultOf<typeof tribeCustomComponentFragment>
export type tribeContactResult = ResultOf<typeof tribeContactFragment>
export type telephoneUrlTriggerType = {
  name: string
  number: string
  value: string
}

export const baseTribeFragment = graphql(/* GraphQL */ `
  fragment BaseTribeInfo on Tribe {
    name
    slug
    heading
    sub_heading
    country
    postal_code
    state
    state_abbreviated
    locality
    address_1
    address_2
    latitude
    longitude
    main_telephone
    organic_number
    paid_number
    telephone_url_triggers
    public_email
    google_place_id
    social_google_url
    social_twitter_url
    social_facebook_url
    social_linkedin_url
    social_instagram_url
    social_youtube_url
    social_pinterest_url
    facebook_review_link
    google_review_url
    yelp_review_url
    meta_description
    meta_title
    whats_app_number
    facebook_pixel_id
  }
`)

export const tribeFragment = graphql(/* GraphQL */ `
  fragment TribeInfo on Tribe {
    ...BaseTribeInfo
    media_collection {
      ...MediaCollectionInfo
    }
  }
`)

export const tribeTypeFragment = graphql(/* GraphQL */ `
  fragment TribeTypeInfo on TribeType {
    name
  }
`)

export const tribeContentFragment = graphql(/* GraphQL */ `
  fragment TribeContentInfo on TribeContent {
    name
    value
    type
  }
`)

export const tribeMenuFragment = graphql(/* GraphQL */ `
  fragment TribeMenuInfo on TribeMenu {
    name
    data
  }
`)

export const tribeLicenseFragment = graphql(/* GraphQL */ `
  fragment TribeLicenseInfo on Tribe {
    ...TribeInfo
    contents(name: "Licenses") {
      ...TribeContentInfo
    }
  }
`)

export const tribeTeamMembersFragment = graphql(/* GraphQL */ `
  fragment TribeTeamMembersInfo on Tribe {
    name
    introduction_team
    teamMembers(active: true) {
      first_name
      last_name
      about
      role
      avatar
      email
      show_on_website
      mobile_e164
      email_public
      mobile_public
      telephone_public
      telephone_number
    }
    contents {
      ...TribeContentInfo
      media_collection {
        ...MediaCollectionInfo
      }
    }
  }
`)

export const tribeContactFragment = graphql(/* GraphQL */ `
  fragment TribeContactInfo on Tribe {
    name
    contents(names: ["Contact Page Message"]) {
      ...TribeContentInfo
    }
  }
`)

export const tribeOpeningHoursFragment = graphql(/* GraphQL */ `
  fragment TribeOpeningHoursInfo on Tribe {
    show_opening_hours
    tribe_opening_hours
    opening_hours_message
  }
`)

export const tribeHomePageFragment = graphql(/* GraphQL */ `
  fragment TribeHomePageInfo on Tribe {
    name
    page_heading
    page_sub_heading
    introduction_bold
    introduction
    media_collection {
      ...MediaCollectionInfo
    }
    contents(names: ["Accolades", "Second Phone Number", "Homepage Video"]) {
      ...TribeContentInfo
      media_collection {
        ...MediaCollectionInfo
      }
    }
  }
`)

export const tribeHomePageVideoFragment = graphql(/* GraphQL */ `
  fragment TribeHomePageVideoInfo on Tribe {
    name
    contents(names: ["Homepage Video"]) {
      ...TribeContentInfo
    }
  }
`)

export const tribeReviewContentFragment = graphql(/* GraphQL */ `
  fragment TribeReviewContentInfo on Tribe {
    name
    contents(names: ["Review Caption"]) {
      ...TribeContentInfo
    }
  }
`)

export const tribeCustomComponentFragment = graphql(/* GraphQL */ `
  fragment TribeCustomComponentInfo on TribeComponent {
    name
    slug
    contents {
      value
      name
      type
    }
  }
`)

export const tribeCustomComponentPageFragment = graphql(/* GraphQL */ `
  fragment TribeCustomComponentPageInfo on Tribe {
    component_types(componentTypeName: $name) {
      name
      status
      components(slug: $componentSlug) {
        ...TribeCustomComponentInfo
      }
    }
  }
`)

export const tribeCustomComponentPagesFragment = graphql(/* GraphQL */ `
  fragment TribeCustomComponentPagesInfo on Tribe {
    component_types(componentTypeName: $name) {
      name
      status
      components {
        name
        slug
      }
    }
  }
`)

export const tribesQuery = graphql(/* GraphQL */ `
  query getTribes($tribeType: String, $order: [String]) {
    tribes(name: $tribeType, orderBy: $order) {
      ...TribeInfo
    }
  }
`)

const tribePromotionBannerQuery = graphql(/* GraphQL */ `
  query getTribePromotionBanner($slug: String!, $status: String) {
    tribe(slug: $slug, status: $status) {
      contents(names: ["Promotion Banner"]) {
        ...TribeContentInfo
      }
    }
  }
`)

const tribeQuery = graphql(/* GraphQL */ `
  query getTribe($slug: String!, $status: String) {
    tribe(slug: $slug, status: $status) {
      ...TribeInfo
      media_collection {
        ...MediaCollectionInfo
      }
    }
  }
`)

const tribeMenusQuery = graphql(/* GraphQL */ `
  query getTribeMenus($slug: String!, $status: String, $tribeType: String, $tribeMenus: [String]) {
    tribe(slug: $slug, status: $status) {
      tribe_menus(tribe_type: $tribeType, menus: $tribeMenus) {
        ...TribeMenuInfo
      }
    }
  }
`)

const searchStoresQuery = graphql(/* GraphQL */ `
  query searchStores(
    $tribe_type: String!
    $address: String!
    $lat: Float!
    $lng: Float!
    $radius: Float!
  ) {
    searchStores(
      tribe_type: $tribe_type
      address: $address
      lat: $lat
      lng: $lng
      radius: $radius
    ) {
      tribe_types {
        ...TribeTypeInfo
      }
      contents(name: "External URL") {
        ...TribeContentInfo
      }
      ...BaseTribeInfo
    }
  }
`)

const tribeLicenseQuery = graphql(/* GraphQL */ `
  query getTribeLicense($slug: String!) {
    tribe(slug: $slug, status: "all") {
      ...TribeLicenseInfo
    }
  }
`)

const tribeTeamMembersQuery = graphql(/* GraphQL */ `
  query getTribeTeamMembers($slug: String!) {
    tribe(slug: $slug, status: "all") {
      ...TribeTeamMembersInfo
    }
  }
`)

const tribeContactQuery = graphql(/* GraphQL */ `
  query getTribeContact($slug: String!) {
    tribe(slug: $slug, status: "all") {
      ...TribeContactInfo
    }
  }
`)

const tribeOpeningHoursQuery = graphql(/* GraphQL */ `
  query getTribeOpeningHours($slug: String!) {
    tribe(slug: $slug, status: "all") {
      ...TribeOpeningHoursInfo
    }
  }
`)

const tribeHomePageQuery = graphql(/* GraphQL */ `
  query getTribeHomePage($slug: String!) {
    tribe(slug: $slug, status: "all") {
      ...TribeHomePageInfo
    }
  }
`)

const tribeHomePageVideoQuery = graphql(/* GraphQL */ `
  query getTribeHomePageVideo($slug: String!) {
    tribe(slug: $slug, status: "all") {
      ...TribeHomePageVideoInfo
    }
  }
`)

const tribeReviewContentQuery = graphql(/* GraphQL */ `
  query getTribeReviewContent($slug: String!) {
    tribe(slug: $slug, status: "all") {
      ...TribeReviewContentInfo
    }
  }
`)

export const tribeCustomComponentPageQuery = graphql(/* GraphQL */ `
  query getTribeCustomComponentPage($slug: String!, $name: [String]!, $componentSlug: String!) {
    tribe(slug: $slug, status: "all") {
      ...TribeCustomComponentPageInfo
    }
  }
`)

export const tribeCustomComponentPagesQuery = graphql(/* GraphQL */ `
  query getTribeCustomComponentPages($slug: String!, $name: [String]!) {
    tribe(slug: $slug, status: "all") {
      ...TribeCustomComponentPagesInfo
    }
  }
`)

export const tribeCustomWebsitePageMenusQuery = graphql(/* GraphQL */ `
  query getTribeCustomWebsitePageMenus($slug: String!) {
    tribe(slug: $slug, status: "all") {
      website_pages {
        slug
        menu_order
        menu_label
        show_in_menu
      }
    }
  }
`)

export const tribeCustomWebsitePageQuery = graphql(/* GraphQL */ `
  query getTribeCustomWebsitePage($slug: String!, $websiteSlug: String!) {
    tribe(slug: $slug, status: "all") {
      website_pages(slug: $websiteSlug) {
        slug
        menu_label
        show_in_menu
        name
        body
        contents {
          name
          source
          type
          value
          media_collection {
            ...MediaCollectionInfo
          }
        }
      }
    }
  }
`)

export const tribeReviewsQuery = graphql(/* GraphQL */ `
  query getTribeReviews($slug: String!) {
    reviews(tribe_slug: $slug, featured: true) {
      name
      review
      featured
      business_name
      rating
    }
  }
`)

export function getTribes({ tribeType, order }: tribesQueryVariables) {
  const variables = {
    tribeType: tribeType,
    order: order
  }

  return useQuery(tribesQuery, variables)
}

export function searchStores(variables: Ref) {
  return useLazyQuery(searchStoresQuery, variables)
}

export function getTribe(slug: string, status = 'Active') {
  const variables = {
    slug: slug,
    status
  }

  return useQuery(tribeQuery, variables)
}

export function getTribeMenus(
  slug: string,
  status = 'Active',
  tribeType: string,
  tribeMenus: string[]
) {
  const variables = {
    slug: slug,
    status,
    tribeType,
    tribeMenus
  }

  return useQuery(tribeMenusQuery, variables)
}

export function getTribeLicense(slug: string) {
  return useQuery(tribeLicenseQuery, {
    slug
  })
}

export function getOpeningHours(slug: string) {
  return useQuery(tribeOpeningHoursQuery, {
    slug
  })
}

export function getTribeHomePage(slug: string) {
  return useQuery(tribeHomePageQuery, {
    slug
  })
}

export function getTeamMembers(slug: string) {
  return useQuery(tribeTeamMembersQuery, {
    slug
  })
}

export function getTribeCustomComponentPages(slug: string, name: string[]) {
  return useQuery(tribeCustomComponentPagesQuery, {
    slug,
    name
  })
}

export function getTribeCustomComponentPage(slug: string, name: string[], componentSlug: string) {
  return useQuery(tribeCustomComponentPageQuery, {
    slug,
    name,
    componentSlug
  })
}

export function getTribeCustomWebsitePageMenus(slug: string) {
  return useQuery(tribeCustomWebsitePageMenusQuery, {
    slug
  })
}

export function getTribeCustomWebsitePageMenu(slug: string, websiteSlug: string) {
  return useQuery(tribeCustomWebsitePageQuery, {
    slug,
    websiteSlug
  })
}

export function getTribeContactPage(slug: string) {
  return useQuery(tribeContactQuery, {
    slug
  })
}

export function getReviews(slug: string) {
  return useQuery(tribeReviewsQuery, {
    slug
  })
}
export function getPromotionBanner(slug: string, status = 'Active') {
  const variables = {
    slug: slug,
    status
  }
  return useQuery(tribePromotionBannerQuery, variables)
}

export function getHomepageVideoBanner(slug: string) {
  return useQuery(tribeHomePageVideoQuery, {
    slug
  })
}

export function getTribeReviewContent(slug: string) {
  return useQuery(tribeReviewContentQuery, {
    slug
  })
}
