import { SupportCountryName } from '@/ts/stores/countryLimits'

enum StorePathName {
  Locations = 'locations',
  Stores = 'stores',
  Store = 'store',
  Magasins = 'magasins'
}

enum BlogPathName {
  Blog = 'blog',
  SignwritersBlog = 'signwriters-blog'
}

enum DistanceUnitName {
  KM = 'km',
  MI = 'mi'
}

enum i18nLocaleName {
  enUS = 'en-US',
  enAU = 'en-AU',
  frFr = 'fr-FR',
  idId = 'id-ID'
}

enum ProductPathName {
  enUS = 'products',
  frFR = 'des-produits'
}

enum StoreTypeName {
  enUS = 'Signarama Stores',
  frFR = 'Signarama France Magasin'
}

const storeListPaths = new Map([
  [SupportCountryName.USA, StorePathName.Locations],
  [SupportCountryName.CANADA, StorePathName.Locations],
  [SupportCountryName.AUSTRALIA, StorePathName.Stores],
  [SupportCountryName.UNITED_KINGDOM, StorePathName.Stores],
  [SupportCountryName.SOUTH_AFRICA, StorePathName.Stores],
  [SupportCountryName.SINGAPORE, StorePathName.Stores],
  [SupportCountryName.INDONESIAN, StorePathName.Stores],
  [SupportCountryName.FRANCE, StorePathName.Magasins]
])

const storePaths = new Map([
  [SupportCountryName.USA, StorePathName.Locations],
  [SupportCountryName.CANADA, StorePathName.Locations],
  [SupportCountryName.AUSTRALIA, StorePathName.Store],
  [SupportCountryName.SOUTH_AFRICA, StorePathName.Store],
  [SupportCountryName.UNITED_KINGDOM, StorePathName.Store],
  [SupportCountryName.SINGAPORE, StorePathName.Store],
  [SupportCountryName.INDONESIAN, StorePathName.Store],
  [SupportCountryName.FRANCE, StorePathName.Magasins]
])

const blogPaths = new Map([
  [SupportCountryName.USA, BlogPathName.Blog],
  [SupportCountryName.CANADA, BlogPathName.Blog],
  [SupportCountryName.AUSTRALIA, BlogPathName.SignwritersBlog],
  [SupportCountryName.SOUTH_AFRICA, BlogPathName.SignwritersBlog],
  [SupportCountryName.UNITED_KINGDOM, BlogPathName.SignwritersBlog],
  [SupportCountryName.SINGAPORE, BlogPathName.SignwritersBlog],
  [SupportCountryName.INDONESIAN, BlogPathName.SignwritersBlog],
  [SupportCountryName.FRANCE, BlogPathName.SignwritersBlog]
])

const distanceUnits = new Map([
  [SupportCountryName.USA, DistanceUnitName.MI],
  [SupportCountryName.CANADA, DistanceUnitName.KM],
  [SupportCountryName.AUSTRALIA, DistanceUnitName.KM],
  [SupportCountryName.SOUTH_AFRICA, DistanceUnitName.KM],
  [SupportCountryName.UNITED_KINGDOM, DistanceUnitName.MI],
  [SupportCountryName.SINGAPORE, DistanceUnitName.KM],
  [SupportCountryName.INDONESIAN, DistanceUnitName.KM],
  [SupportCountryName.FRANCE, DistanceUnitName.KM]
])

const i18nLocales = new Map([
  [SupportCountryName.USA, i18nLocaleName.enUS],
  [SupportCountryName.CANADA, i18nLocaleName.enUS],
  [SupportCountryName.AUSTRALIA, i18nLocaleName.enAU],
  [SupportCountryName.SOUTH_AFRICA, i18nLocaleName.enUS],
  [SupportCountryName.UNITED_KINGDOM, i18nLocaleName.enUS],
  [SupportCountryName.SINGAPORE, i18nLocaleName.enUS],
  [SupportCountryName.INDONESIAN, i18nLocaleName.idId],
  [SupportCountryName.FRANCE, i18nLocaleName.frFr]
])

const productPaths = new Map([
  [SupportCountryName.USA, ProductPathName.enUS],
  [SupportCountryName.CANADA, ProductPathName.enUS],
  [SupportCountryName.AUSTRALIA, ProductPathName.enUS],
  [SupportCountryName.SOUTH_AFRICA, ProductPathName.enUS],
  [SupportCountryName.UNITED_KINGDOM, ProductPathName.enUS],
  [SupportCountryName.SINGAPORE, ProductPathName.enUS],
  [SupportCountryName.INDONESIAN, ProductPathName.enUS],
  [SupportCountryName.FRANCE, ProductPathName.frFR]
])

const storeTypes = new Map([
  [SupportCountryName.USA, StoreTypeName.enUS],
  [SupportCountryName.CANADA, StoreTypeName.enUS],
  [SupportCountryName.AUSTRALIA, StoreTypeName.enUS],
  [SupportCountryName.SOUTH_AFRICA, StoreTypeName.enUS],
  [SupportCountryName.UNITED_KINGDOM, StoreTypeName.enUS],
  [SupportCountryName.SINGAPORE, StoreTypeName.enUS],
  [SupportCountryName.INDONESIAN, StoreTypeName.enUS],
  [SupportCountryName.FRANCE, StoreTypeName.frFR]
])

export const storeListPath = storeListPaths.get(import.meta.env.VITE_COUNTRY as SupportCountryName)

export const storePath = storePaths.get(import.meta.env.VITE_COUNTRY as SupportCountryName)
export const productPath = productPaths.get(import.meta.env.VITE_COUNTRY as SupportCountryName)

export const blogPath = blogPaths.get(import.meta.env.VITE_COUNTRY as SupportCountryName)

export const distanceUnit = distanceUnits.get(import.meta.env.VITE_COUNTRY as SupportCountryName)

export const i18nLocale =
  i18nLocales.get(import.meta.env.VITE_COUNTRY as SupportCountryName) || 'en-US'

export const storeType =
  storeTypes.get(import.meta.env.VITE_COUNTRY as SupportCountryName) ||
  storeTypes.get(SupportCountryName.USA)!
