import { useQuery } from '@vue/apollo-composable'
import { graphql } from '@/ts/graphql/__generated__'
import type { ResultOf } from '@graphql-typed-document-node/core'
export type WebsiteSectionInfoResult = ResultOf<typeof websiteSectionFragment>
export type WebsiteContentInfoResult = ResultOf<typeof websiteContentFragment>

export const websiteSectionFragment = graphql(/* GraphQL */ `
  fragment WebsiteSectionInfo on WebsiteSection {
    name
    contents {
      ...WebsiteContentInfo
    }
  }
`)

export const websiteContentFragment = graphql(/* GraphQL */ `
  fragment WebsiteContentInfo on WebsiteContent {
    name
    type
    value
    media_collection {
      ...MediaCollectionInfo
    }
  }
`)

export const websiteSectionQuery = graphql(/* GraphQL */ `
  query getWebsiteSection($name: [String]) {
    websiteSections(name: $name) {
      ...WebsiteSectionInfo
    }
  }
`)

export function getWebsiteSection(name: string) {
  return useQuery(websiteSectionQuery, {
    name
  })
}
