<script setup lang="ts">
import { ref, onMounted, inject } from 'vue'
import type { DropzoneFileWithUploadURL, DropzoneInstance } from '@/ts/services/dropzoneService'
import 'dropzone/dist/basic.css'
import 'dropzone/dist/dropzone.css'
import { useI18n } from 'vue-i18n'
import { DefaultDropzoneInstance } from '@/ts/providers'

const emits = defineEmits<{ success: [file: DropzoneFileWithUploadURL] }>()
const { t } = useI18n()
const elem = ref<HTMLElement | null>(null)
const dropzone = inject<DropzoneInstance>(DefaultDropzoneInstance)

onMounted(() => {
  if (!dropzone) {
    throw new Error('Dropzone not initialized')
  }

  if (elem.value) {
    dropzone.init(elem.value)
    dropzone.on('success', (file) => {
      emits('success', file as DropzoneFileWithUploadURL)
    })
  }
})
</script>

<template>
  <div
    ref="elem"
    class="dropzone"
  >
    <div
      class="dz-message"
      data-dz-message
    >
      <span>
        {{ t('Drop files here to upload') }}
      </span>
    </div>
  </div>
</template>

<style lang="scss">
.dropzone {
  @apply border-[#ccd0d2];

  .dz-preview {
    &.dz-image-preview {
      border-radius: 20px;
    }
    .dz-remove {
      z-index: 10;
      position: absolute;
      display: block;
      top: 0%;
      left: 0%;
      margin-left: -16px;
      margin-top: -16px;
    }

    .dz-remove svg {
      fill: #444;
      cursor: pointer;
    }

    .dz-success-mark {
      background-color: rgb(102, 187, 106, 0.8) !important;
    }

    .dz-success-mark svg {
      font-size: 54px;
      fill: #fff !important;
    }

    .dz-error-mark {
      background-color: rgba(239, 83, 80, 0.8) !important;
    }

    .dz-error-mark svg {
      font-size: 54px;
      fill: #fff !important;
    }

    .dz-progress {
      @apply hidden;
    }

    &.dz-processing {
      .dz-progress {
        @apply block;
      }
    }
  }
}
</style>
