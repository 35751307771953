type shouldBeComponentType = {
  street_number: string[]
  address_1: string[]
  postal_code: string[]
  state: string[]
  locality: string[]
  country: string[]
}

type shouldBeType = keyof shouldBeComponentType
export type FormattedAddressType = {
  address_1: string
  locality: string
  state: string
  country: string
  postal_code: string
}

export function getAddressFormatObject(
  address_components: google.maps.places.AddressComponent[]
): FormattedAddressType {
  const shouldBeComponent: shouldBeComponentType = {
    street_number: ['street_number'],
    address_1: ['street_address', 'route'],
    postal_code: ['postal_code'],
    state: [
      'administrative_area_level_1',
      'administrative_area_level_2',
      'administrative_area_level_3',
      'administrative_area_level_4',
      'administrative_area_level_5'
    ],
    locality: [
      'locality',
      'sublocality',
      'sublocality_level_1',
      'sublocality_level_2',
      'sublocality_level_3',
      'sublocality_level_4'
    ],
    country: ['country']
  }
  const address = {
    street_number: '',
    address_1: '',
    postal_code: '',
    state: '',
    locality: '',
    country: ''
  }
  address_components.forEach((component) => {
    Object.entries(shouldBeComponent).forEach(([shouldBe, value]) => {
      if (value.indexOf(component.types[0]) !== -1) {
        if (shouldBe === 'country') {
          address[shouldBe] = component.shortText || ''
        } else {
          address[shouldBe as shouldBeType] = component.longText || ''
        }
      }
    })
  })
  return {
    address_1: `${address.street_number} ${address.address_1}`.trim(),
    locality: address.locality,
    state: address.state,
    country: address.country,
    postal_code: address.postal_code
  }
}
