import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'
import type { getTribeInfoResult, telephoneUrlTriggerType } from '@/ts/api/tribes'
import { useCountryLimitsStore } from '@/ts/stores/countryLimits'

export type LocationInfo = {
  name: string | null
  slug: string | null
  email: string | null
  phone: string | null
  organic_number: string | null
  paid_number: string | null
  address_1: string | null
  address_2: string | null
  locality: string | null
  state: string | null
  country: string | null
  postal_code: string | null
  latitude: string | null
  longitude: string | null
  social_google_url: string | null
  social_twitter_url: string | null
  social_facebook_url: string | null
  social_linkedin_url: string | null
  social_instagram_url: string | null
  social_youtube_url: string | null
  social_pinterest_url: string | null
  facebook_review_link: string | null
  google_review_url: string | null
  yelp_review_url: string | null
  google_place_id: string | null
  meta_description: string | null
  meta_title: string | null
  telephone_url_triggers: telephoneUrlTriggerType[]
  whats_app_number: string | null
}

const defaultState = {
  name: null,
  slug: null,
  email: null,
  phone: null,
  organic_number: null,
  paid_number: null,
  address_1: null,
  address_2: null,
  locality: null,
  state: null,
  country: null,
  postal_code: null,
  latitude: null,
  longitude: null,
  social_google_url: null,
  social_twitter_url: null,
  social_facebook_url: null,
  social_linkedin_url: null,
  social_instagram_url: null,
  social_youtube_url: null,
  social_pinterest_url: null,
  facebook_review_link: null,
  google_review_url: null,
  yelp_review_url: null,
  google_place_id: null,
  meta_description: null,
  meta_title: null,
  telephone_url_triggers: [],
  whats_app_number: null
}

const locationStorage = useStorage<LocationInfo>('location_info', { ...defaultState })

export const useLocationStore = defineStore('locationsInfo', () => {
  const state = ref({ ...locationStorage.value })

  const sync = () => {
    state.value = {
      ...locationStorage.value
    }
  }
  const reset = () => {
    locationStorage.value = { ...defaultState }
    sync()
  }

  const setLocation = (payload: LocationInfo) => {
    locationStorage.value = {
      name: payload.name,
      slug: payload.slug,
      email: payload.email,
      phone: payload.phone,
      organic_number: payload.organic_number,
      paid_number: payload.paid_number,
      address_1: payload.address_1,
      address_2: payload.address_2,
      locality: payload.locality,
      state: payload.state,
      country: payload.country,
      postal_code: payload.postal_code,
      latitude: payload.latitude,
      longitude: payload.longitude,
      social_google_url: payload.social_google_url,
      social_twitter_url: payload.social_twitter_url,
      social_facebook_url: payload.social_facebook_url,
      social_linkedin_url: payload.social_linkedin_url,
      social_instagram_url: payload.social_instagram_url,
      facebook_review_link: payload.facebook_review_link,
      social_youtube_url: payload.social_youtube_url,
      social_pinterest_url: payload.social_pinterest_url,
      google_review_url: payload.google_review_url,
      yelp_review_url: payload.yelp_review_url,
      google_place_id: payload.google_place_id,
      meta_description: payload.meta_description,
      meta_title: payload.meta_title,
      telephone_url_triggers: payload.telephone_url_triggers ?? [],
      whats_app_number: payload.whats_app_number
    }
    sync()
  }

  const phone = computed(() => {
    if (state.value.telephone_url_triggers.length > 0) {
      const params = new URLSearchParams(window.location.search)

      const trigger = state.value.telephone_url_triggers.find((trigger) => {
        const value = params.get(trigger.name)
        return (
          value !== null &&
          value !== undefined &&
          value !== '' &&
          value.toString() === trigger.value.toString()
        )
      })
      if (trigger) {
        return trigger.number
      }
    }

    if (window.source === 'paid') {
      return state.value.paid_number || state.value.organic_number || state.value.phone
    }

    return state.value.organic_number ?? state.value.phone
  })
  const whatsAppNumber = computed(() => {
    return state.value.whats_app_number
  })
  const whatsAppNumberLink = computed(() => {
    if (whatsAppNumber.value) {
      return `https://wa.me/${whatsAppNumber.value.replace(/[\s|-]+/, '')}`
    }
    return null
  })
  const name = computed(() => state.value.name)
  const slug = computed(() => state.value.slug)
  const address = computed(() => ({
    address_1: state.value.address_1,
    address_2: state.value.address_2,
    locality: state.value.locality,
    state: state.value.state,
    country: state.value.country,
    postal_code: state.value.postal_code
  }))
  const email = computed(() => state.value.email)
  const googleUrl = computed(() => state.value.social_google_url)
  const twitterUrl = computed(() => state.value.social_twitter_url)
  const facebookUrl = computed(() => state.value.social_facebook_url)
  const linkedinUrl = computed(() => state.value.social_linkedin_url)
  const instagramUrl = computed(() => state.value.social_instagram_url)
  const facebookReviewUrl = computed(() => state.value.facebook_review_link)
  const googleReviewUrl = computed(() => state.value.google_review_url)
  const yelpReviewUrl = computed(() => state.value.yelp_review_url)
  const youtubeUrl = computed(() => state.value.social_youtube_url)
  const pinterestUrl = computed(() => state.value.social_pinterest_url)
  const lockedStore = computed(() => !!state.value.slug)
  const latitude = computed(() => state.value.latitude)
  const longitude = computed(() => state.value.longitude)
  const meta = computed(() => {
    return {
      title: state.value.meta_title,
      description: state.value.meta_description
    }
  })
  const googleMapUrl = computed(() => {
    if (state.value.latitude && state.value.longitude) {
      return `https://www.google.com/maps/search/?api=1&query=${state.value.latitude},${state.value.longitude}&query_place_id=${state.value.google_place_id}`
    }
    return null
  })

  const showLocationEmail = computed(() => {
    const { state: countryState } = useCountryLimitsStore()
    return countryState.isAU && ['alexandria', 'tullamarine'].includes(state.value.slug!)
  })

  const mapLocationData = (tribe: getTribeInfoResult) => {
    return {
      name: tribe.name,
      slug: tribe.slug,
      email: tribe.public_email,
      phone: tribe.main_telephone || null,
      organic_number: tribe.organic_number || null,
      paid_number: tribe.paid_number || null,
      address_1: tribe.address_1,
      address_2: tribe.address_2,
      locality: tribe.locality,
      state: tribe.state,
      country: tribe.country,
      postal_code: tribe.postal_code,
      latitude: tribe.latitude,
      longitude: tribe.longitude,
      social_google_url: tribe.social_google_url,
      social_twitter_url: tribe.social_twitter_url,
      social_facebook_url: tribe.social_facebook_url,
      social_linkedin_url: tribe.social_linkedin_url,
      social_instagram_url: tribe.social_instagram_url,
      social_youtube_url: tribe.social_youtube_url,
      social_pinterest_url: tribe.social_pinterest_url,
      facebook_review_link: tribe.facebook_review_link,
      google_review_url: tribe.google_review_url,
      yelp_review_url: tribe.yelp_review_url,
      google_place_id: tribe.google_place_id,
      meta_description: tribe.meta_description,
      meta_title: tribe.meta_title,
      telephone_url_triggers: tribe.telephone_url_triggers,
      whats_app_number: tribe.whats_app_number
    }
  }

  return {
    name,
    slug,
    state,
    reset,
    setLocation,
    mapLocationData,
    phone,
    whatsAppNumber,
    whatsAppNumberLink,
    address,
    email,
    showLocationEmail,
    latitude,
    longitude,
    googleUrl,
    twitterUrl,
    facebookUrl,
    linkedinUrl,
    instagramUrl,
    googleMapUrl,
    facebookReviewUrl,
    googleReviewUrl,
    youtubeUrl,
    pinterestUrl,
    yelpReviewUrl,
    meta,
    lockedStore
  }
})
