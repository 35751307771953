<script lang="ts" setup>
import { ref, toRefs, watch } from 'vue'
const props = withDefaults(
  defineProps<{
    defaultImg: string
    hoverImg?: string
    altText: string
    lock: boolean
  }>(),
  {
    defaultImg: '',
    hoverImg: '',
    altText: '',
    lock: false
  }
)

const { defaultImg, hoverImg, lock } = toRefs(props)

const currentImage = ref(defaultImg.value)
watch(lock, (val) => {
  currentImage.value = val ? hoverImg.value : defaultImg.value
})
</script>

<template>
  <img
    :src="currentImage"
    :alt="altText"
  />
</template>
