import Swal from 'sweetalert2'

export const useSwal = () => {
  const success = async (title: string, text: string, confirmButtonText = 'Ok') => {
    await Swal.fire({
      title,
      text,
      confirmButtonText,
      icon: 'success'
    })
  }

  const error = async (title: string, html: string, confirmButtonText = 'Ok') => {
    await Swal.fire({
      title,
      html,
      confirmButtonText,
      icon: 'error'
    })
  }

  return {
    success,
    error
  }
}
