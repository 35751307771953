<script setup lang="ts">
withDefaults(
  defineProps<{
    label?: string
    id: string
    rows?: number
    labelClass?: string
    error?: null | undefined | string
    errorClass?: string
  }>(),
  {
    rows: 3,
    labelClass: 'text-gray-900',
    errorClass: 'text-red-400'
  }
)
defineOptions({
  inheritAttrs: false
})

const emit = defineEmits<{
  (event: 'change-value'): void
}>()

const model = defineModel<string>()

const onInput = (event: Event) => {
  const el = event.target as HTMLInputElement
  model.value = el?.value
}
</script>

<template>
  <div class="w-full">
    <label
      :for="id"
      :class="[labelClass, 'block text-sm font-bold leading-normal']"
    >
      <slot name="label">
        {{ label }}
      </slot>
    </label>
    <div class="mt-2">
      <textarea
        v-bind="$attrs"
        :id="id"
        :rows="rows"
        class="block w-full rounded-md border-[#ccd0d2] py-1.5 text-base text-gray-900 shadow-sm focus:border-[#98cbe8] sm:text-sm sm:leading-6"
        :value="modelValue"
        @change="emit('change-value')"
        @input="onInput"
      />
    </div>
    <p
      v-if="error"
      class="mt-2 text-sm"
      :class="errorClass"
    >
      {{ error }}
    </p>
  </div>
</template>

<style scoped></style>
