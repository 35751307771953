import { ref, computed } from 'vue'
import { useHead } from '@unhead/vue'

const name = ref('')
const image = ref('')
const description = ref('')
const address_1 = ref('')
const address_2 = ref('')
const locality = ref('')
const postal_code = ref('')
const country = ref('')
const state = ref('')
const phone = ref('')
const email = ref('')

export const useLocalBusinessSchema = () => {
  const jsonLD = computed(() => {
    const localBusinessJsonLD = {
      '@context': 'https://schema.org',
      '@type': 'LocalBusiness',
      name: `Signarama-${name.value}`,
      description: description.value,
      address: {
        '@type': 'PostalAddress',
        streetAddress: `${address_1.value} ${address_2.value}`,
        addressLocality: locality.value,
        postalCode: postal_code.value,
        addressCountry: country.value,
        addressRegion: state.value
      },
      telephone: phone.value,
      email: email.value,
      image: image.value
    }

    return [
      {
        hid: 'local-business-schema',
        type: 'application/ld+json',
        textContent: JSON.stringify(localBusinessJsonLD)
      }
    ]
  })

  const createLD = () => {
    useHead({
      script: () => jsonLD.value
    })
  }

  return {
    jsonLD,
    name,
    description,
    address_1,
    address_2,
    locality,
    postal_code,
    country,
    state,
    phone,
    email,
    image,
    createLD
  }
}
