<script setup lang="ts">
import { getTribeLicense, type getTribeLicenseResult } from '@/ts/api/tribes'
import { useLocationStore } from '@/ts/stores/location'
import { computed, ref } from 'vue'
import { getValueByName } from '@/ts/services/websiteContentValue'

const locationStore = useLocationStore()

const result = ref<getTribeLicenseResult | undefined>(undefined)

if (locationStore.slug) {
  const { onResult } = getTribeLicense(locationStore.slug)
  onResult(({ data }) => {
    if (data?.tribe) {
      const { tribe } = data
      result.value = tribe
    }
  })
}

const licenses = computed(() => {
  if (result.value && result.value) {
    const content = getValueByName('Licenses', result.value)
    if (content) {
      return JSON.parse(content)
    }
  }
  return []
})
</script>

<template>
  <div v-if="licenses && licenses.length > 0">
    <div
      v-for="license in licenses"
      :key="license.name"
      class="py-4"
    >
      <span class="font-bold"> {{ license.value }} </span>
      <br />
      <span>
        {{ license.name }}
      </span>
    </div>
  </div>
</template>

<style scoped></style>
