import { getWebsiteSection } from '@/ts/api/websiteContents'
import { computed } from 'vue'
import { getValueByName } from '@/ts/services/websiteContentValue'
import { SupportCountryName, useCountryLimitsStore } from '@/ts/stores/countryLimits'

enum SectionName {
  US = 'Homepage',
  FR = 'homepage'
}

const sectionNameMap = new Map([
  [SupportCountryName.USA, SectionName.US],
  [SupportCountryName.FRANCE, SectionName.FR]
])

enum TitleName {
  US = 'Title',
  FR = 'Titre'
}
enum DescriptionName {
  US = 'Page Introduction',
  FR = 'Legende Principale'
}

enum MetaTitleName {
  US = 'Meta Page Title'
}

enum MetaDescriptionName {
  US = 'Meta Page Description'
}

export const Title = new Map([
  [SupportCountryName.USA, TitleName.US],
  [SupportCountryName.FRANCE, TitleName.FR]
])

export const Description = new Map([
  [SupportCountryName.USA, DescriptionName.US],
  [SupportCountryName.FRANCE, DescriptionName.FR]
])

export const MetaTitle = new Map([[SupportCountryName.USA, MetaTitleName.US]])

export const MetaDescription = new Map([[SupportCountryName.USA, MetaDescriptionName.US]])

export const FieldNameMap = {
  Title,
  Description,
  MetaTitle,
  MetaDescription
}
export const useHomepageContent = () => {
  const countryLimitsStore = useCountryLimitsStore()

  const { result, loading } = getWebsiteSection(
    sectionNameMap.get(countryLimitsStore.state.country) ??
      sectionNameMap.get(SupportCountryName.USA)!
  )

  const data = computed(() => {
    if (
      !loading.value &&
      result.value &&
      result.value.websiteSections &&
      result.value.websiteSections.length > 0
    ) {
      const section = result.value.websiteSections[0]

      if (section) {
        return {
          title: getValueByName(
            Title.get(countryLimitsStore.state.country) ?? Title.get(SupportCountryName.USA)!,
            section
          ),
          description: getValueByName(
            Description.get(countryLimitsStore.state.country) ??
              Description.get(SupportCountryName.USA)!,
            section
          ),
          meta_title: getValueByName(MetaTitle.get(SupportCountryName.USA)!, section)!,
          meta_description: getValueByName(MetaDescription.get(SupportCountryName.USA)!, section)
        }
      }
    }
    return null
  })

  return {
    data,
    loading
  }
}
