<script lang="ts" setup>
import { computed } from 'vue'
import classnames from 'classnames'
import { vElementHover } from '@vueuse/components'
import { useMouseInElement, watchDebounced } from '@vueuse/core'
import MenuLevelOneBtn from '@/ts/components/menu/MenuLevelOneBtn.vue'
import SearchBar from '@/ts/components/menu/SearchBar.vue'
import { VExpandTransition } from '@/ts/components/transitions'
import { reactive, ref } from 'vue'
import { useWebsiteMenuService } from '@/ts/services/websiteMenus/websiteMenuService'
import type { WebsiteMenuItemType } from '@/ts/services/websiteMenus/transformer'
import { DesktopMenu as DesktopMenuSkeleton } from '@/ts/components/skeleton/menu'

const showSearchBar = ref(false)
const MenuTargetEle = ref(null)
const SubMenuTargetEle = ref(null)
const menuInfo = reactive<{
  parentId: null | number
  label: string
  children: WebsiteMenuItemType[]
}>({
  parentId: null,
  label: '',
  children: []
})

const subMenuInfo = reactive<{ parentId: null | number; children: WebsiteMenuItemType[] }>({
  parentId: null,
  children: []
})
const menuBackground = ref('')
const { isOutside: mainMenuIsOutSide } = useMouseInElement(MenuTargetEle)
const { isOutside: subMenuIsOutSide } = useMouseInElement(SubMenuTargetEle)

const { data, loading: menuLoading } = useWebsiteMenuService()

const mainMenus = computed(() => data.value)

const outSideAllMenu = computed<boolean>(() => mainMenuIsOutSide.value && subMenuIsOutSide.value)

const hasMenu = computed<boolean>(() => menuInfo.children.length > 0)

const menuHover = (state: boolean, item: WebsiteMenuItemType) => {
  if (showSearchBar.value) {
    return
  }
  menuInfo.parentId = item.id
  menuInfo.label = item.label
  menuInfo.children = [...item.children]

  if (state) {
    subMenuInfo.children.length = 0
    menuBackground.value = ''
  }
}
const subMenuHover = (state: boolean, item: WebsiteMenuItemType) => {
  subMenuInfo.parentId = item.id
  subMenuInfo.children = [...item.children]

  if (state && item.image.length > 0) {
    menuBackground.value = item.image[0]
  }
}

const thirdMenuHover = (state: boolean, item: WebsiteMenuItemType) => {
  if (state && item.image.length > 0) {
    menuBackground.value = item.image[0]
  }
}

const clearOutSideMenu = (state: boolean) => {
  if (!state) {
    clearSubMenuOutSide(false)
    menuInfo.parentId = null
    menuInfo.label = ''
    menuInfo.children.length = 0
  }
}

const clearSubMenuOutSide = (state: boolean) => {
  if (!state) {
    menuBackground.value = ''
    subMenuInfo.parentId = null
    subMenuInfo.children.length = 0
  }
}

watchDebounced(
  outSideAllMenu,
  (val) => {
    if (val) {
      clearOutSideMenu(false)
      clearSubMenuOutSide(false)
    }
  },
  { debounce: 100 }
)
</script>

<template>
  <div
    ref="MenuTargetEle"
    class="relative flex pb-1.5"
  >
    <ul
      v-if="!menuLoading && mainMenus"
      class="flex w-full space-x-[2px]"
    >
      <MenuLevelOneBtn
        class="grow-0"
        as="router-link"
        :as-bind="{ to: { name: 'Home' } }"
      >
        <FontAwesomeIcon
          class="mx-2 text-black xl:mx-0"
          icon="fa-solid fa-house-chimney"
        />
      </MenuLevelOneBtn>
      <MenuLevelOneBtn
        v-for="(item, key) in mainMenus.children"
        class="grow"
        as="a"
        :asBind="{
          href: item.path,
          target: item.url_target
        }"
        :class="{ active: menuInfo.parentId === item.id && menuInfo.children.length > 0 }"
        :key="key"
        v-element-hover="(state) => menuHover(state, item)"
      >
        {{ item.label }}
      </MenuLevelOneBtn>
      <MenuLevelOneBtn
        class="grow-0"
        @click="showSearchBar = !showSearchBar"
      >
        <FontAwesomeIcon
          class="mx-2 text-black xl:mx-0"
          icon="fa-solid fa-magnifying-glass"
        />
      </MenuLevelOneBtn>
    </ul>
    <!-- skeleton: loading menu -->
    <DesktopMenuSkeleton v-else></DesktopMenuSkeleton>
    <!-- sub menu -->
    <div
      ref="SubMenuTargetEle"
      class="absolute top-[calc(100%)] z-[1] w-full bg-[#A09797] p-5"
      :class="
        classnames({
          visible: hasMenu,
          invisible: !hasMenu
        })
      "
      v-element-hover="clearOutSideMenu"
    >
      <div class="pb-4 text-2xl font-light uppercase text-white">{{ menuInfo.label }}</div>
      <div class="flex">
        <div class="flex w-1/4 flex-col">
          <a
            v-for="(subItem, subKey) in menuInfo.children"
            class="flex cursor-pointer items-center justify-between px-4 py-2 leading-6 hover:bg-white hover:text-primary"
            :class="[subItem.id === subMenuInfo.parentId ? 'bg-white text-primary' : 'text-white']"
            :key="`${subKey}_${subItem.id}`"
            :href="subItem.path"
            v-element-hover="(state) => subMenuHover(state, subItem)"
          >
            {{ subItem.label }}
            <FontAwesomeIcon
              v-if="subItem.children.length > 0"
              class="text-xl"
              icon="fa-solid fa-angle-right"
            />
          </a>
        </div>
        <div
          class="flex w-3/4"
          v-element-hover="clearSubMenuOutSide"
        >
          <div
            class="flex min-h-[350px] w-full bg-[#A99E9E]"
            :class="{ invisible: subMenuInfo.children.length === 0 }"
          >
            <div class="flex w-1/3 flex-col">
              <a
                v-for="(thirdItem, thirdKey) in subMenuInfo.children"
                class="group relative cursor-pointer px-4 leading-10 text-white hover:bg-primary"
                :key="`${thirdKey}_${thirdItem.id}`"
                :href="thirdItem.path"
                v-element-hover="(state) => thirdMenuHover(state, thirdItem)"
              >
                {{ thirdItem.label }}
                <div
                  class="invisible absolute right-[-19px] top-0 size-0 border-y-[20px] border-l-[20px] border-y-transparent border-l-primary group-hover:visible"
                ></div>
              </a>
            </div>
            <div
              class="flex min-h-[350px] w-2/3 bg-gray-100 bg-cover bg-center bg-no-repeat"
              :style="{ backgroundImage: `url('${menuBackground}')` }"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <!-- Search Bar -->
    <VExpandTransition>
      <SearchBar
        class="top-[calc(100%)] transition-all duration-[250ms]"
        v-if="showSearchBar"
        @close="showSearchBar = false"
      ></SearchBar>
    </VExpandTransition>
  </div>
</template>
