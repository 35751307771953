import { SupportCountryName, useCountryLimitsStore } from '@/ts/stores/countryLimits'
import { getWebsiteMenu } from '@/ts/api/websiteMenus'
import { computed } from 'vue'
import { mapMenu } from '@/ts/services/websiteMenus/transformer'

enum WebsiteMenuNameSet {
  US = 'Corporate Menu',
  FR = 'Menu Pied de page'
}

const WebsiteMenuName = new Map([
  [SupportCountryName.USA, WebsiteMenuNameSet.US],
  [SupportCountryName.FRANCE, WebsiteMenuNameSet.FR]
])
export function useFooterMenuService() {
  const countryLimitsStore = useCountryLimitsStore()

  const { result, loading } = getWebsiteMenu(
    WebsiteMenuName.get(countryLimitsStore.state.country) ??
      WebsiteMenuName.get(SupportCountryName.USA)!
  )

  const data = computed(() => {
    if (!loading.value && result.value && result.value?.websiteMenu) {
      return mapMenu(result.value?.websiteMenu.menu_json)
    }
    return null
  })

  return {
    data
  }
}
