import { SupportCountryName, useCountryLimitsStore } from '@/ts/stores/countryLimits'
import { getWebsiteMenu } from '@/ts/api/websiteMenus'
import { ref } from 'vue'
import { mapMenu, type WebsiteMenuType } from '@/ts/services/websiteMenus/transformer'
import { provideApolloClient } from '@vue/apollo-composable'
import { apolloClient } from '@/ts/plugins/apolloClient'

enum WebsiteMenuNameSet {
  US = 'Main Menu',
  FR = 'Menu Principal'
}

const WebsiteMenuName = new Map([
  [SupportCountryName.USA, WebsiteMenuNameSet.US],
  [SupportCountryName.FRANCE, WebsiteMenuNameSet.FR]
])

const data = ref<null | WebsiteMenuType>(null)
const loading = ref(true)

export function useWebsiteMenuService() {
  const countryLimitsStore = useCountryLimitsStore()

  const fetch = (slug: string | null) => {
    const { onResult } = provideApolloClient(apolloClient)(() =>
      getWebsiteMenu(
        WebsiteMenuName.get(countryLimitsStore.state.country) ??
          WebsiteMenuName.get(SupportCountryName.USA)!,
        slug
      )
    )

    onResult(({ loading: _loading, data: _data }) => {
      loading.value = _loading
      if (!_loading) {
        if (_data && _data.websiteMenu) {
          data.value = mapMenu(_data.websiteMenu.menu_json)
          return
        }

        // meaning tribe menu not found, get default main one
        if (slug && _data.websiteMenu === null) {
          const { onResult: onMainMenuResult } = provideApolloClient(apolloClient)(() =>
            getWebsiteMenu(
              WebsiteMenuName.get(countryLimitsStore.state.country) ??
                WebsiteMenuName.get(SupportCountryName.USA)!
            )
          )

          onMainMenuResult(({ loading: _loading, data: _data }) => {
            loading.value = _loading
            if (!_loading) {
              if (_data && _data.websiteMenu) {
                data.value = mapMenu(_data.websiteMenu.menu_json)
                return
              }
            }
          })
        }
      }
    })
  }

  return {
    loading,
    data,
    fetch
  }
}
