import type { MediaCollectionInfoResult, MediaInfoResult } from '@/ts/api/media'

export type MediaHasImgKeys = keyof Pick<
  MediaInfoResult,
  'banner' | 'default' | 'original_cropped' | 'portrait' | 'rectangle' | 'square' | 'thumbnail'
>

export function getImageByMediaCollectionName(
  mediaCollectionName: string,
  size: MediaHasImgKeys,
  mediaCollections: MediaCollectionInfoResult[]
) {
  const images = getAllImagesByMediaCollectionName(mediaCollectionName, size, mediaCollections)

  if (images.length > 0) {
    return images[0]
  }
  return ''
}

export function getAllImagesByMediaCollectionName(
  mediaCollectionName: string,
  size: MediaHasImgKeys,
  mediaCollections: MediaCollectionInfoResult[]
) {
  if (!mediaCollections || mediaCollections.length === 0) {
    return []
  }
  const mediaCollection = mediaCollections.find(
    (mediaCollection) => mediaCollection.name === mediaCollectionName
  )

  if (mediaCollection && mediaCollection.media.length > 0) {
    return mediaCollection.media.map((media) => media[size] || '')
  }

  return []
}
