<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { Listbox, ListboxButton, ListboxOptions, ListboxOption } from '@headlessui/vue'
import { ChevronUpDownIcon } from '@heroicons/vue/20/solid'
import { useFooterContent } from '@/ts/services/websiteContents/footerContent'
import { useCountryLimitsStore } from '@/ts/stores/countryLimits'
import { useLocationStore } from '@/ts/stores/location'
import { STATIC_SOURCES_URL } from '@/ts/stores/os'
import ResponsiveImage from '@/ts/components/core/ResponsiveImage.vue'
import { useFooterMenuService } from '@/ts/services/websiteMenus/footerMenuService'
import LicenseSection from '@/ts/components/footer/LicenseSection.vue'
import { useEnquiryStore } from '@/ts/stores/enquiry'
import { getTribeHomePage, type getTribeHomePageResult } from '@/ts/api/tribes'
import { getValueByName } from '@/ts/services/websiteContentValue'
import WhatsAppIcon from '@/assets/images/Icons-whatsapp.svg'

type CountryType = {
  url: string
  name: string
  unavailable: boolean
}

interface SocialLink {
  name: string
  link: string
  icon: string
}

const { state } = useCountryLimitsStore()
const dialogStore = useEnquiryStore()
const { t } = useI18n()
const locationStore = useLocationStore()
const footerContent = useFooterContent()
const footerMenu = useFooterMenuService()
import { analyticsIns } from '@/ts/services/analytics'

const tribeDetailResult = ref<getTribeHomePageResult | null>(null)

if (locationStore.slug) {
  const { onResult: onTribeDetailResult } = getTribeHomePage(locationStore.slug)
  onTribeDetailResult(({ loading, data }) => {
    if (!loading && data) {
      tribeDetailResult.value = data.tribe
    }
  })
}

const countries = [
  { url: 'https://www.signarama.com.au', name: 'Australia', unavailable: false },
  { url: 'https://signarama.ca', name: 'Canada', unavailable: false },
  { url: 'https://www.signarama.fr', name: 'France', unavailable: false },
  { url: 'https://www.signarama.com.my', name: 'Malaysia', unavailable: false },
  { url: 'https://www.speedysigns.co.nz', name: 'New Zealand', unavailable: false },
  { url: 'https://www.signarama.com.sg', name: 'Singapore', unavailable: false },
  { url: 'https://www.signarama.co.za', name: 'South Africa', unavailable: false },
  { url: 'https://www.signarama.co.uk', name: 'United Kingdom', unavailable: false },
  { url: 'https://signarama.com', name: 'United States', unavailable: false },
  { url: 'https://signarama.co.id', name: 'Indonesia', unavailable: false }
]
const defaultCountry = countries.findIndex(
  (country) => country.url.indexOf(window.location.origin) > -1
)
const selectedCountry = ref(countries[defaultCountry > -1 ? defaultCountry : 0])

const defaultCountryImg = computed(() => {
  return state.isFR
    ? `${STATIC_SOURCES_URL}/images/La-franchise-Signarama-vous-interesse.jpg`
    : `${STATIC_SOURCES_URL}/images/signarama-usa-cafe-restaurants-original.jpg`
})

const createSocialLink = (name: string, link: string, icon: string): SocialLink => ({
  name,
  link,
  icon
})

const socialLinks = computed(() => {
  const links: SocialLink[] = []
  const orgLinks: SocialLink[] = []

  const addLink = (
    link: string | undefined | null,
    name: string,
    icon: string,
    targetArray: SocialLink[]
  ) => {
    if (link) {
      targetArray.push(createSocialLink(name, link!, icon))
    }
  }

  // Add social links from footer content
  addLink(
    footerContent.data.value?.socialLinks?.facebook,
    'Facebook',
    'fa-brands fa-facebook-f',
    orgLinks
  )

  addLink(
    footerContent.data.value?.socialLinks?.linkedin,
    'LinkedIn',
    'fa-brands fa-linkedin-in',
    orgLinks
  )

  addLink(
    footerContent.data.value?.socialLinks?.pinterest,
    'Pinterest',
    'fa-brands fa-pinterest-p',
    orgLinks
  )

  addLink(
    footerContent.data.value?.socialLinks?.youtube,
    'YouTube',
    'fa-brands fa-youtube',
    orgLinks
  )

  addLink(
    footerContent.data.value?.socialLinks?.instagram,
    'Instagram',
    'fa-brands fa-instagram',
    orgLinks
  )

  // Add more social links here
  addLink(locationStore.facebookUrl, 'Facebook', 'fa-brands fa-facebook-f', links)
  addLink(locationStore.youtubeUrl, 'YouTube', 'fa-brands fa-youtube', links)
  addLink(locationStore.instagramUrl, 'Instagram', 'fa-brands fa-instagram', links)
  addLink(locationStore.linkedinUrl, 'LinkedIn', 'fa-brands fa-linkedin-in', links)
  addLink(locationStore.twitterUrl, 'Twitter', 'fa-brands fa-x-twitter', links)
  addLink(locationStore.pinterestUrl, 'Pinterest', 'fa-brands fa-pinterest-p', links)

  return locationStore.lockedStore ? links : orgLinks
})

const menus = computed(() => {
  if (footerMenu.data.value) {
    return footerMenu.data.value?.children.map((item) => {
      return {
        label: item.label,
        url: item.url
      }
    })
  }
  return []
})

const store = computed(() => {
  if (tribeDetailResult.value && tribeDetailResult.value) {
    const tribeDetail = tribeDetailResult.value
    return {
      ...tribeDetail,
      secondPhone: tribeDetail.contents ? getValueByName('Second Phone Number', tribeDetail) : null
    }
  }

  return null
})

const onClickedEmail = async () => {
  await analyticsIns.value?.track('Email Click', {
    Tribe: locationStore.name
  })
}

const onSelectedCountry = (value: CountryType) => {
  window.location.href = value.url
}

const openQuote = () => {
  dialogStore.toggle(true)
}
</script>

<template>
  <div class="flex flex-col bg-[#625C5C] text-white">
    <div class="container relative mx-auto pb-32 pt-6">
      <div class="grid grid-cols-1 gap-x-10 gap-y-8 px-6 md:grid-cols-3 md:px-4 lg:px-0">
        <!-- Contact Us -->
        <div class="flex flex-col text-center sm:text-left">
          <h3 class="pb-4 text-xl font-light">
            {{ t('contact us') }}
          </h3>
          <p class="text-base">
            <span v-if="locationStore.lockedStore">
              {{ t('Contact Team', { store_name: locationStore.name || '' }) }}
            </span>
            <span
              v-else
              class="whitespace-pre-line"
            >
              {{ t('Find your local store or call our national number') }}:
            </span>
          </p>
          <template v-if="locationStore.lockedStore">
            <a
              class="py-3 text-3xl font-light"
              :href="`tel:${locationStore.phone}`"
            >
              <span>
                <FontAwesomeIcon icon="fa-solid fa-phone" />
              </span>
              {{ locationStore.phone }}
            </a>
            <a
              v-if="store?.secondPhone"
              class="py-3 text-3xl font-light"
              :href="`tel:${store?.secondPhone}`"
            >
              <span>
                <FontAwesomeIcon icon="fa-solid fa-phone" />
              </span>
              {{ store?.secondPhone }}
            </a>
            <a
              v-if="locationStore.whatsAppNumberLink"
              class="flex items-center gap-x-1 py-3 text-3xl font-light"
              :href="locationStore.whatsAppNumberLink"
              target="_blank"
            >
              <WhatsAppIcon class="size-8 fill-white" />
              {{ locationStore.whatsAppNumber }}
            </a>
            <a
              v-if="locationStore.showLocationEmail"
              :href="`mailto:${locationStore.email}?subject=New%20Website%20Enquiry`"
              @click="onClickedEmail"
              class="flex items-center gap-x-1 overflow-hidden py-3 font-light"
            >
              <span class="text-3xl">
                <FontAwesomeIcon icon="fa-solid fa-envelope" />
              </span>
              <span class="truncate text-xl">
                {{ locationStore.email }}
              </span>
            </a>
          </template>

          <a
            v-else
            class="py-7 text-3xl font-light"
            :href="footerContent.data.value?.phone.url"
          >
            {{ footerContent.data.value?.phone.text }}
          </a>
          <a
            class="hover:underline"
            v-if="locationStore.lockedStore"
            :href="locationStore.googleMapUrl || ''"
          >
            <span class="font-bold">Signarama {{ locationStore.name }}</span
            ><br />
            <i18n-t
              class="whitespace-pre-line"
              keypath="Address Link"
              tag="span"
            >
              <template #address>
                <span
                  >{{ locationStore.address.address_1 }} {{ locationStore.address.address_2 }}</span
                >
              </template>
              <template #locality>
                <span>{{ locationStore.address.locality }}</span>
              </template>
              <template #state>
                <span>{{ locationStore.address.state }}</span>
              </template>
              <template #postal_code>
                <span>{{ locationStore.address.postal_code }}</span>
              </template>
            </i18n-t>
          </a>
          <RouterLink
            v-else
            class="text-sm hover:underline"
            :class="{ 'text-[#e27063]': state.isFR }"
            :to="{ name: 'Locations' }"
          >
            {{ t('Click here for a list of all stores') }}
          </RouterLink>
          <LicenseSection v-if="locationStore.lockedStore"></LicenseSection>
          <h3 class="mt-8 pb-4 text-xl font-light">
            {{ t('Follow Us') }}
          </h3>
          <div class="mb-12 flex justify-center space-x-3 sm:justify-start">
            <a
              class="flex size-[2.2rem] items-center justify-center rounded-full border"
              v-for="(social, key) in socialLinks"
              :key="key"
              :href="social.link"
              target="_blank"
            >
              <FontAwesomeIcon
                :icon="social.icon"
                size="lg"
              />
            </a>
          </div>
          <div v-if="state.isFR">
            <button
              @click="openQuote"
              class="flex items-center justify-between rounded border px-4 py-2"
              :class="{
                'rounded-lg border-none bg-primary px-6 py-3 text-xl font-semibold':
                  locationStore.lockedStore
              }"
            >
              {{ t('enquire now') }}
            </button>
          </div>
          <h3 class="mt-8 pb-4 text-xl font-light">
            {{ t('Select your country') }}
          </h3>
          <div class="flex justify-center sm:justify-start">
            <Listbox
              :modelValue="selectedCountry"
              @update:modelValue="onSelectedCountry"
            >
              <div class="relative min-w-40 max-w-56">
                <ListboxButton
                  class="flex w-full items-center justify-between rounded border px-4 py-2"
                  >{{ selectedCountry.name }}
                  <ChevronUpDownIcon
                    class="size-5 text-gray-400"
                    aria-hidden="true"
                  />
                </ListboxButton>
                <ListboxOptions
                  class="absolute bottom-[calc(100%+5px)] mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm"
                >
                  <ListboxOption
                    v-for="country in countries"
                    :key="country.url"
                    :value="country"
                    :disabled="country.unavailable"
                    v-slot="{ active }"
                  >
                    <div
                      class="cursor-pointer px-3 py-2 text-black"
                      :class="{ 'bg-gray-100': active }"
                    >
                      {{ country.name }}
                    </div>
                  </ListboxOption>
                </ListboxOptions>
              </div>
            </Listbox>
          </div>
        </div>
        <!-- About Signarama -->
        <div class="flex flex-col text-center sm:text-left">
          <h3 class="pb-4 text-xl font-light">
            {{ t('About Signarama') }}
          </h3>
          <p class="text-base">
            {{ footerContent.data.value?.description }}
          </p>
          <ul class="py-6">
            <li
              class="space-x-2 py-1"
              v-for="(menu, key) in menus"
              :key="key"
            >
              <FontAwesomeIcon
                class="text-[#F1685D]"
                icon="fa-solid fa-right-long"
              />
              <a
                class="font-semibold"
                :href="menu.url"
              >
                {{ menu.label }}
              </a>
            </li>
          </ul>
        </div>
        <!-- Store info -->
        <div class="flex">
          <div class="flex flex-col">
            <div class="flex flex-col bg-[#787272] p-3">
              <a
                class="relative"
                :href="footerContent.data.value?.franchise.url || ''"
              >
                <ResponsiveImage
                  width="376"
                  height="209"
                  alt="Franchise Signarama"
                  class="bg w-full"
                  :src="footerContent.data.value?.franchise?.image || defaultCountryImg"
                ></ResponsiveImage>
                <div
                  class="absolute bottom-0 w-full bg-white px-3 py-1.5 text-xl font-semibold text-primary"
                >
                  {{ t('Own a Signarama franchise') }}
                </div>
              </a>
              <div class="py-4 text-base">
                {{ footerContent.data.value?.franchise.description }}
              </div>
              <div>
                <a
                  v-if="
                    footerContent.data.value?.franchise && footerContent.data.value?.franchise.url
                  "
                  class="text-lg font-semibold hover:underline"
                  :href="footerContent.data.value?.franchise.url"
                >
                  {{ t('Apply Today') }} >
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-center bg-black p-2 text-xs text-white">
      <div>
        Copyright Signarama. All Rights Reserved.
        <a
          class="inline hover:underline"
          href="https://gorilladash.com"
          target="_blank"
        >
          Powered by Gorilla Dash.
        </a>
      </div>
    </div>
  </div>
</template>
