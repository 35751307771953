import { defineStore } from 'pinia'
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'

export const useBreakPointsStore = defineStore('breakPoint', () => {
  const breakpoints = useBreakpoints(breakpointsTailwind)
  const smallerThanMd = breakpoints.smaller('md')
  const smallerThanLg = breakpoints.smaller('lg')
  const isDesktop = breakpoints.greaterOrEqual('lg')
  return {
    smallerThanMd,
    smallerThanLg,
    isDesktop
  }
})
