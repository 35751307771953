import Analytics from 'analytics'
import type { AnalyticsInstance } from 'analytics'
import { ref, type Ref } from 'vue'

export const analyticsIns: Ref<AnalyticsInstance | null> = ref(null)

export const initAnalytics = async (plugins: Record<string, unknown>[]) => {
  analyticsIns.value = Analytics({
    app: 'signarama',
    plugins
  })
  await analyticsIns.value.page()
}
