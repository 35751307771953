export const config = {
  instance: undefined,
  pixelId: null,
  autoConfig: true,
  initialized: false
}

function fbp(pluginConfig = {}) {
  return {
    name: 'facebook-pixel',
    config: {
      ...config,
      ...pluginConfig
    },
    initialize: ({ config }) => {
      const { pixelId } = config
      if (!pixelId) {
        throw new Error('No facebook pixel id defined.')
      }

      scriptLoaded(config)
    },
    page: ({ config }) => {
      if (config.initialized) {
        window.fbq('track', 'PageView')
      }
    },
    /* Track event */
    track: ({ payload }) => {
      window.fbq('track', payload.event, payload.properties)
    },
    loaded: ({ config }) => {
      return config.initialized
    }
  }
}

export default fbp

function scriptLoaded(config) {
  config.initialized = typeof window !== 'undefined' && !!window.fbq
  if (!config.initialized) {
    /* eslint-disable */
    !function(f,b,e,v,n,t,s){
      if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
      n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
      document,'script','https://connect.facebook.net/en_US/fbevents.js');
    if (config.autoConfig) {
      // reference: https://developers.facebook.com/docs/facebook-pixel/advanced
      window.fbq('set', 'autoConfig', false, config.pixelId)
    }

    fbq('init', config.pixelId)
    fbq('track', 'PageView')
    config.initialized = true
    config.instance = fbq
  }
}
