import type { RouteLocationNormalized, Router, RouteRecordRaw } from 'vue-router'
import { ref } from 'vue'
import { storePath, productPath } from '@/ts/services/variables/countryVariable'
import router from '@/ts/routes/index'

type removeRouteCallback = () => void
let dynamicRoutes: removeRouteCallback[] = []
const slug = ref<string | null | undefined>(null)
const currentSlug = ref<string | null | undefined>(null)

export const sharePages = [
  // ProductDetails
  {
    name: 'ProductDetails',
    path: `/${productPath}/:product_slug`,
    component: () => import('@/ts/pages/products/ProductDetailPage.vue')
  },
  // categoryDetails
  {
    name: 'CategoryDetails',
    path: '/categories/:category_slug',
    component: () => import('@/ts/pages/categories/CategoryDetailPage.vue')
  },
  {
    name: 'CategoryProductDetails',
    path: '/categories/:category_slug/:product_slug',
    component: () => import('@/ts/pages/products/ProductDetailPage.vue')
  },
  {
    name: 'ContactThankYou',
    path: '/contact/thank-you',
    component: () => import('@/ts/pages/contact/ThankYouPage.vue')
  }
]

export const useSharePageRouter = (_slug?: string | null | undefined) => {
  currentSlug.value = _slug
  const addRoutes = (router: Router) => {
    slug.value = currentSlug.value
    dynamicRoutes = generateRoute().map((route) => router.addRoute(route))
  }

  const reset = (newSlug?: string | null | undefined) => {
    currentSlug.value = newSlug
    slug.value = newSlug
    removeRoutes()
  }

  const removeRoutes = () => {
    dynamicRoutes.forEach((removeRoute) => removeRoute())
    dynamicRoutes = []
  }

  const generateRoute = (): RouteRecordRaw[] => {
    if (slug.value) {
      return [
        {
          name: 'Home',
          path: '/',
          redirect: {
            name: 'Welcome',
            params: { slug: slug.value }
          }
        },
        {
          path: `/${storePath}/:slug`,
          component: () => import('@/ts/layouts/LocationLayout.vue'),
          beforeEnter: (to) => {
            reset(to.params.slug.toString())
            addRoutes(router)
          },
          children: sharePages.map((page) => ({
            ...page,
            path: page.path.substring(1)
          }))
        },
        ...(sharePages.map((page) => {
          return {
            path: page.path,
            redirect: (to: RouteLocationNormalized) => {
              return {
                name: page.name,
                params: {
                  slug: slug.value,
                  ...to.params
                }
              }
            }
          }
        }) as RouteRecordRaw[])
      ]
    }
    return [
      { name: 'Home', path: '/', component: () => import('@/ts/pages/HomePage.vue') },
      ...sharePages
    ]
  }

  return {
    slug,
    addRoutes,
    removeRoutes,
    reset,
    generateRoute
  }
}
