import { useQuery } from '@vue/apollo-composable'
import { graphql } from '@/ts/graphql/__generated__'
import type { ResultOf } from '@graphql-typed-document-node/core'

export type PromotionInfoResult = ResultOf<typeof promotionFragment>
export type PromotionWebsiteContentResult = ResultOf<typeof promotionWebsiteContentFragment>
export type PromotionBannerInfoResult = ResultOf<typeof promotionBannerFragment>

const promotionWebsiteContentFragment = graphql(/* GraphQL */ `
  fragment PromotionWebsiteContentInfo on PromotionWebsiteContent {
    name
    type
    value
    products
    media_collection {
      ...MediaCollectionInfo
    }
  }
`)

const promotionFragment = graphql(/* GraphQL */ `
  fragment PromotionInfo on Promotion {
    name
    slug
    type
    status
    fields
    start_date
    end_date
    website_content {
      ...PromotionWebsiteContentInfo
    }
  }
`)

const promotionBannerFragment = graphql(/* GraphQL */ `
  fragment PromotionBannerInfo on PromotionBanner {
    name
    value
    media_collection {
      ...MediaCollectionInfo
    }
  }
`)

const livePromotionBannerQuery = graphql(/* GraphQL */ `
  query getLivePromotionBanner {
    promotionBanner {
      name
      start_date
      end_date
      banner {
        ...PromotionBannerInfo
      }
    }
  }
`)

const promotionQuery = graphql(/* GraphQL */ `
  query getPromotion($slug: String!) {
    promotion(slug: $slug) {
      ...PromotionInfo
    }
  }
`)

export function getPromotion(slug: string) {
  return useQuery(promotionQuery, {
    slug
  })
}

export function getLivePromotionBanner() {
  return useQuery(livePromotionBannerQuery)
}
