<script setup lang="ts">
import { toRefs } from 'vue'
import { useI18n } from 'vue-i18n'
import BaseEnquiryForm from '@/ts/components/enquiries/form/dialog/BaseEnquiryForm.vue'
import TextInput from '@/ts/components/inputs/TextInput.vue'
import GoogleAutocomplete from '@/ts/components/google/GoogleAutocomplete.vue'
import type { EnquiryFormType } from '@/ts/api/enquiries'
import type { Form } from 'laravel-precognition-vue/dist/types'

const rootProps = defineProps<{
  form: EnquiryFormType & Form<EnquiryFormType>
}>()
const { form } = toRefs(rootProps)
const { t } = useI18n()
</script>

<template>
  <BaseEnquiryForm :form="form">
    <template v-slot:default="{ form }">
      <div class="col-span-2">
        <label
          for="google_autocomplete"
          class="block text-sm font-bold leading-normal text-gray-900"
        >
          {{ t('Business Address') }}
        </label>
        <div class="mt-2">
          <GoogleAutocomplete
            id="google_autocomplete"
            v-model="form.searchLatLng"
            v-model:address="form.searchAddress"
            v-model:addressComponents="form.searchAddressComponents"
            class="block w-full rounded-md border-[#ccd0d2] py-1.5 text-base text-gray-900 shadow-sm focus:border-[#98cbe8] sm:text-sm sm:leading-6"
            :error="form.errors.searchAddress"
          >
          </GoogleAutocomplete>
        </div>
      </div>
      <div class="col-span-2">
        <TextInput
          id="product_type"
          :label="t('Product Type')"
          v-model="form.product_type"
          @change-value="form.validate('product_type')"
          :error="form.errors.product_type"
        ></TextInput>
      </div>
    </template>
  </BaseEnquiryForm>
</template>

<style scoped></style>
