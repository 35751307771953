import { useScriptTag } from '@vueuse/core'
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'
import { SupportCountryName } from '@/ts/stores/countryLimits'

export const loadUserWay = () => {
  const { scriptTag, load, unload } = useScriptTag('https://cdn.userway.org/widget.js', () => {}, {
    manual: true,
    defer: true,
    attrs: {
      'data-account': import.meta.env.VITE_USERWAY_ACCOUNT
    }
  })

  return {
    scriptTag,
    load,
    unload
  }
}

export const createUserWay = async () => {
  const loadUserWayScript = loadUserWay()

  if (
    import.meta.env.MODE !== 'development' &&
    import.meta.env.VITE_COUNTRY === SupportCountryName.USA &&
    useBreakpoints(breakpointsTailwind).greaterOrEqual('lg')
  ) {
    await loadUserWayScript.load()
  }
}
