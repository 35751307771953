<script setup lang="ts">
import TextInput from '@/ts/components/inputs/TextInput.vue'
import TextareaInput from '@/ts/components/inputs/TextareaInput.vue'
import { useI18n } from 'vue-i18n'
import type { EnquiryFormType } from '@/ts/api/enquiries'
import { ref, toRefs } from 'vue'
import type { Form } from 'laravel-precognition-vue/dist/types'
import DropzoneUploader from '@/ts/components/uploaders/DropzoneUploader.vue'
import { type DropzoneFileWithUploadURL } from '@/ts/services/dropzoneService'

const rootProps = defineProps<{
  form: EnquiryFormType & Form<EnquiryFormType>
}>()

const { form } = toRefs(rootProps)

const { t } = useI18n()

const showDropzone = ref(false)

const onSuccess = (file: DropzoneFileWithUploadURL) => {
  form.value.files.push({
    bucket: 'client-upload-temp',
    path: file.path,
    filename: file.name
  })
}
</script>

<template>
  <div class="grid grid-cols-2 gap-5">
    <div class="col-span-2 md:col-span-1">
      <TextInput
        id="first_name"
        :label="t('First Name') + ' *'"
        v-model="form.first_name"
        @change-value="form.validate('first_name')"
        :error="form.errors.first_name"
      ></TextInput>
    </div>
    <div class="col-span-2 md:col-span-1">
      <TextInput
        id="last_name"
        :label="t('Last Name') + ' *'"
        v-model="form.last_name"
        @change-value="form.validate('last_name')"
        :error="form.errors.last_name"
      ></TextInput>
    </div>
    <div class="col-span-2">
      <TextInput
        id="business_name"
        :label="t('Business Name') + ' *'"
        v-model="form.business_name"
        @change-value="form.validate('business_name')"
        :error="form.errors.business_name"
      ></TextInput>
    </div>
    <div class="col-span-2">
      <TextInput
        id="email"
        :label="t('Email Address') + ' *'"
        v-model="form.email"
        @change-value="form.validate('email')"
        :error="form.errors.email"
      ></TextInput>
    </div>
    <div class="col-span-2">
      <TextInput
        id="contact_number"
        :label="t('Contact Number') + ' *'"
        v-model="form.phone"
        @change-value="form.validate('phone')"
        :error="form.errors.phone"
      ></TextInput>
    </div>
    <slot :form="form"></slot>

    <div class="col-span-2">
      <button
        v-if="!showDropzone"
        class="flex items-center space-x-2 rounded-2xl bg-[#817373] px-5 py-2 text-sm text-white"
        @click.prevent="showDropzone = true"
      >
        <FontAwesomeIcon icon="fa-solid fa-upload" />
        <span>
          {{ t('Upload Files') }}
        </span>
      </button>
      <DropzoneUploader
        v-else
        @success="onSuccess"
      ></DropzoneUploader>
    </div>

    <div class="col-span-2">
      <TextareaInput
        id="message"
        :label="t('Enquiry Message')"
        v-model="form.message"
        @change-value="form.validate('message')"
        :error="form.errors.message"
      >
      </TextareaInput>
    </div>

    <slot
      name="bottom"
      :form="form"
    ></slot>
  </div>
</template>

<style scoped></style>
