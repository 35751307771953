import { defineStore } from 'pinia'
import { computed, reactive, readonly } from 'vue'
import { useStorage } from '@vueuse/core'
import type { viewedProduct } from '@/ts/api/enquiries'

const productStorage = useStorage<viewedProduct[]>('products', [])

export const useEnquiryStore = defineStore('enquiryStore', () => {
  const state = reactive({
    open: false
  })

  const toggle = (value: boolean) => {
    state.open = value
  }

  const isOpen = computed(() => {
    return state.open
  })

  const viewProduct = (id: number) => {
    if (productStorage.value && productStorage.value.find((product) => product.id === id)) {
      return
    }
    productStorage.value.push({ id })
  }

  const resetViewedProducts = () => {
    productStorage.value = []
  }

  const getProducts = computed(() => {
    return productStorage.value
  })

  return {
    state: readonly(state),
    isOpen,
    getProducts,
    toggle,
    viewProduct,
    resetViewedProducts
  }
})
