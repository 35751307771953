<script lang="tsx" setup>
import { toRefs, shallowRef, ref, onMounted } from 'vue'
import type { ComponentPublicInstance } from 'vue'
import type { ComponentType, componentsMapType, ComponentOption, SetStepType } from './IndustryType'
import HoverImg from './HoverImg.vue'
import IsHover from './IsHover.vue'

type OptionType = {
  name: string
  image: string
  hoverImage: string
  component: ComponentType
}
const props = defineProps<{ options: OptionType[]; componentMap: componentsMapType }>()
const activeCompEle = ref<ComponentPublicInstance<{ isLockSwitch?: boolean }> | null>(null)
const { options, componentMap } = toRefs(props)
const selectStep = ref(1)
const selectCardComp = shallowRef<ComponentOption | null>(null)

const setStep: SetStepType = (params) => {
  if (
    activeCompEle.value &&
    typeof activeCompEle.value.isLockSwitch === 'boolean' &&
    activeCompEle.value.isLockSwitch
  ) {
    console.warn('[component] Please select an option')
    return
  }
  const { step = null, stepName = null } = params
  let compOpt: ComponentOption | null = null

  if (stepName) {
    selectStep.value = options.value.findIndex((option) => option.component === stepName) + 1
    compOpt = componentMap.value[stepName] || null
  }

  if (step) {
    selectStep.value = step
    compOpt = componentMap.value[options.value[step - 1].component] || null
  }

  if (compOpt) {
    selectCardComp.value = compOpt
    moveArrow()
    scrollToNext()
  }
}

const scrollToNext = () => {
  const pickerWrap = document.querySelector('.picker-wrap') as HTMLElement | null
  if (pickerWrap) {
    const elementOffsetTop = pickerWrap.getBoundingClientRect().top
    const elementScrollPosition = window.scrollY + elementOffsetTop - 100
    window.scrollTo({
      top: elementScrollPosition,
      behavior: 'smooth'
    })
  }
}

const moveArrow = () => {
  const stepButton = document.querySelector(
    `.step-button[data-itme-key="${selectStep.value - 1}"]`
  ) as HTMLElement | null
  if (stepButton) {
    const arrowPosition = stepButton.offsetTop + stepButton.offsetHeight / 2
    const stepArrowEle = document.querySelector('.card-arrow') as HTMLElement | null
    if (stepArrowEle) {
      stepArrowEle.style.top = `${arrowPosition - 28.25}px`
    }
  }
}

onMounted(() => {
  selectCardComp.value = componentMap.value[options.value[0].component] || null
})
</script>

<template>
  <div class="picker-wrap">
    <div class="step-wrap">
      <div
        class="button-wrap"
        v-for="(option, key) in options"
        :key="key"
      >
        <IsHover v-slot="{ isHover }">
          <button
            class="step-button"
            :data-itme-key="key"
            @click="setStep({ stepName: option.component })"
          >
            <HoverImg
              :default-img="option.image"
              :hover-img="option.hoverImage"
              :alt-text="option.name"
              :lock="isHover || selectStep > key"
            ></HoverImg>
          </button>
        </IsHover>
        <div
          v-show="key + 1 < options.length"
          class="option-line"
        ></div>
      </div>
    </div>
    <div class="options-wrap">
      <div class="card-arrow"></div>
      <div class="options-card">
        <Transition
          name="fade-step"
          mode="out-in"
        >
          <component
            ref="activeCompEle"
            :is="selectCardComp?.comp"
            :stepNum="selectStep"
            :propsData="selectCardComp?.propsData"
            v-on="selectCardComp?.on || {}"
            @setStep="setStep"
          >
            <template v-slot:header>
              <div class="header-wrap">
                <div class="step-num">STEP {{ selectStep }}</div>
                <div class="title">{{ selectCardComp?.title }}</div>
                <p
                  class="subtitle"
                  v-show="selectCardComp?.subTitle"
                >
                  {{ selectCardComp?.subTitle }}
                </p>
              </div>
            </template>
          </component>
        </Transition>
      </div>
    </div>
  </div>
</template>

<style>
.fade-step-enter-active,
.fade-step-leave-active {
  transition: opacity 0.3s ease;
}

.fade-step-enter-from,
.fade-step-leave-to {
  opacity: 0;
}
</style>

<style lang="scss" scoped>
.picker-wrap {
  @apply relative px-4 leading-6 lg:w-full flex lg:flex-row w-full flex-col lg:gap-x-11;
}

.step-wrap {
  @apply flex w-full lg:w-auto justify-between lg:justify-start lg:px-10 py-4 lg:py-0 relative m-0 h-auto leading-6 lg:flex-col;
}

.options-wrap {
  @apply flex w-full relative m-0 h-auto leading-6;
}

.options-card {
  background: #f6f6f6;
  @apply relative p-8 w-full h-auto leading-6 rounded-3xl;
  box-shadow: -7px 9px 26px -12px rgba(0, 0, 0, 0.75);
}

.button-wrap {
  @apply flex items-center flex-col;
}

.option-line {
  @apply hidden lg:block w-0 border-dotted border-l-[3px] border-[#b8b8b8] h-24;
}

.header-wrap {
  @apply flex flex-col;
  .step-num {
    @apply pb-2 mb-2 text-xl leading-8 text-center text-black border-b border-black border-dotted;
  }
  .title {
    @apply text-3xl text-center text-[#e14d3c] py-4;
  }
  .subtitle {
    @apply mx-0 mt-2 mb-3 text-base font-normal leading-6 text-center text-[#333];
  }
}

.card-arrow {
  top: 36.25px;
  @apply hidden lg:block absolute left-0 w-0 h-0 z-10 transition-all duration-500;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    margin-left: 0;
    bottom: 0;
    box-sizing: border-box;
    border: 20px solid black;
    border-color: transparent transparent #f6f6f6 #f6f6f6;
    transform-origin: 0 0;
    transform: rotate(45deg);
    box-shadow: -3px 4px 3px 0 rgba(0, 0, 0, 0.1);
  }
}
</style>
