<script setup lang="ts">
import * as Sentry from '@sentry/vue'
import { TransitionRoot, TransitionChild, Dialog, DialogPanel, DialogTitle } from '@headlessui/vue'
import { computed, onMounted, ref, provide, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import USAEnquiryForm from '@/ts/components/enquiries/form/dialog/USAEnquiryForm.vue'
import { ContactFormName, type ThankYouMessageType, useEnquiryForm } from '@/ts/api/enquiries'
import { useLocationStore } from '@/ts/stores/location'
import GoogleAutocomplete from '@/ts/components/google/GoogleAutocomplete.vue'
import { useGoogleSearch } from '@/ts/services/searchStoreService'
import { vOnClickOutside } from '@vueuse/components'
import StoreListResult from '@/ts/components/stores/StoreListResult.vue'
import { type getTribeInfoResult } from '@/ts/api/tribes'
import { useReCpatcha } from '@/ts/services/google/recaptcha'
import { SupportCountryName, useCountryLimitsStore } from '@/ts/stores/countryLimits'
import { useDropzone } from '@/ts/services/dropzoneService'
import { useSwal } from '@/ts/services/swal'
import { useEnquiryStore } from '@/ts/stores/enquiry'
import { useRouter } from 'vue-router'
import { useUserTrackingData } from '@/ts/routes/middlewares/userTrackingData'
import { useSharePageRouter } from '@/ts/routes/sharePageRouter'
import { DefaultDropzoneInstance } from '@/ts/providers'
import FREnquiryForm from '@/ts/components/enquiries/form/dialog/FREnquiryForm.vue'
import BaseEnquiryForm from '@/ts/components/enquiries/form/dialog/BaseEnquiryForm.vue'
import ZAEnquiryForm from '@/ts/components/enquiries/form/dialog/ZAEnquiryForm.vue'
import { useFavouriteStore } from '@/ts/stores/favourites'
import collect from 'collect.js'
import { analyticsIns } from '@/ts/services/analytics'
import WhatsAppIcon from '@/assets/images/Icons-whatsapp.svg'

const { t } = useI18n()
const router = useRouter()
const changeLocation = ref(false)
const locationStore = useLocationStore()
const { state } = useCountryLimitsStore()
const favouriteStore = useFavouriteStore()
const { onSearchBlur, loading, storeSearch, onSearchStores, showResultList, stores, clearStores } =
  useGoogleSearch()
const recaptcha = useReCpatcha()
const swal = useSwal()
const dialogStore = useEnquiryStore()
const { getUserTrackingDataQuery, state: trackingData } = useUserTrackingData()
const formName = ContactFormName.get(state.country) || ContactFormName.get(SupportCountryName.USA)!
// dropzone
const dropzone = useDropzone()
provide(DefaultDropzoneInstance, dropzone)

const { form, thankYouMessage } = useEnquiryForm(formName, locationStore.slug)

const checkHasFiles = () => {
  // when precognition successfully, process dropzone if has files
  // otherwise, submit form
  if (dropzone.hasFiles()) {
    // if queue processed, submit form
    dropzone.on('queuecomplete', onSubmit)
    dropzone.processQueue()
  } else {
    onSubmit()
  }
}

const onSubmit = async () => {
  try {
    form.g_recaptcha_token = await recaptcha.execute('contact_us')
    form.tracking_data = collect(Object.fromEntries(trackingData.value)).values().all()
    form.products = dialogStore.getProducts
    const { data } = (await form.submit()) as Awaited<
      Promise<{ data: { data: ThankYouMessageType } }>
    >
    await analyticsIns.value?.track('Website Enquiry', {
      Form: formName,
      Location: locationStore.slug
    })
    thankYouMessage.value = {
      ...data.data,
      data: {
        name: `${form.first_name} ${form.last_name}`.trim(),
        email: form.email,
        telephone: form.phone,
        message: form.message
      }
    }
    form.reset()
    dropzone.reset()
    dialogStore.resetViewedProducts()
    favouriteStore.reset()
    dialogStore.toggle(false)

    await router.push({
      name: 'ContactThankYou',
      params: {
        slug: locationStore.slug
      },
      query: getUserTrackingDataQuery.value
    })
  } catch (e) {
    Sentry.captureException(e)
    await swal.error(
      t('Error'),
      t('There was an error submitting your enquiry. <br />Please try again later.')
    )
  }
}

const closeModal = () => {
  dialogStore.toggle(false)
  form.reset()
  dropzone.reset()
}

const onSelectedStore = (item: getTribeInfoResult) => {
  locationStore.setLocation(locationStore.mapLocationData(item))
  changeLocation.value = false
  clearStores()
  const sharePagesService = useSharePageRouter(locationStore.slug)
  sharePagesService.reset(locationStore.slug)
  sharePagesService.addRoutes(router)
}

const tribe = computed(() => {
  if (changeLocation.value) {
    return null
  }
  return {
    name: locationStore.name,
    slug: locationStore.slug,
    phone: locationStore.phone,
    whatsAppNumber: locationStore.whatsAppNumber,
    whatsAppNumberLink: locationStore.whatsAppNumberLink
  }
})

watch(
  () => locationStore.slug,
  (newValue) => {
    form.tribe_slug = newValue
  }
)

onMounted(async () => {
  await recaptcha.recaptchaLoaded()
})
</script>

<template>
  <TransitionRoot
    appear
    :show="dialogStore.isOpen"
    as="template"
  >
    <Dialog
      static
      as="div"
      @close="() => {}"
      class="relative z-50"
    >
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-black/25"
          aria-hidden="true"
        />
      </TransitionChild>
      <div class="fixed inset-0 w-screen overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-2xl overflow-hidden bg-white text-left align-middle shadow-xl transition-all"
            >
              <DialogTitle
                as="h3"
                class="flex justify-between bg-primary p-6 text-2xl font-light leading-6 text-white"
              >
                <span>
                  {{ t('Send an enquiry or get a quote') }}
                </span>
                <button @click="closeModal">
                  <FontAwesomeIcon icon="fa-solid fa-close" />
                </button>
              </DialogTitle>
              <div class="m-6 flex flex-col gap-y-5">
                <div
                  v-if="locationStore.lockedStore && !changeLocation"
                  class="bg-primary p-6 text-white"
                >
                  <div v-if="tribe">
                    <div class="flex flex-col">
                      <div class="space-y-3 break-keep text-2xl leading-6">
                        <p class="font-light">
                          {{ t('This enquiry will be sent to') }}
                        </p>
                        <p class="font-bold">
                          {{ tribe.name }}
                        </p>
                      </div>

                      <a
                        class="cursor-pointer py-3 text-3xl font-light leading-6"
                        :href="`tel:${tribe.phone}`"
                      >
                        <span class="text-2xl">
                          <FontAwesomeIcon
                            v-if="tribe.whatsAppNumber"
                            icon="fa-solid fa-phone"
                          />
                        </span>
                        {{ tribe.phone }}
                      </a>
                      <a
                        v-if="tribe.whatsAppNumber"
                        class="flex cursor-pointer gap-x-1.5 py-3 text-3xl font-light leading-6"
                        :href="`${tribe.whatsAppNumberLink}`"
                        target="_blank"
                      >
                        <WhatsAppIcon class="size-7 fill-white" />
                        {{ tribe.whatsAppNumber }}
                      </a>

                      <button
                        class="w-[100px] cursor-pointer font-light text-white"
                        @click="changeLocation = true"
                      >
                        {{ t('Change store') }}
                      </button>
                    </div>
                  </div>
                  <div v-else></div>
                </div>
                <div
                  class="bg-primary px-10 py-5"
                  v-else
                >
                  <div class="mb-3 mt-5 text-2xl font-extralight text-white">
                    {{ t('Select your closest store') }}
                  </div>
                  <div
                    v-on-click-outside="() => onSearchBlur()"
                    class="flex items-center space-x-2 rounded-lg border-2 bg-white p-2"
                  >
                    <FontAwesomeIcon
                      class="text-[#555555]"
                      :icon="loading ? 'fa-solid fa-sync' : 'fa-solid fa-magnifying-glass'"
                      :spin="loading"
                    />
                    <GoogleAutocomplete
                      class="w-full border-0 bg-transparent p-0 text-xs placeholder:text-[#b1b7ba] focus:ring-0"
                      :placeholder="t('Zip code, city, or state')"
                      v-model="storeSearch.searchLatLng"
                      v-model:address="storeSearch.searchAddress"
                      @change="onSearchStores"
                      @focus="showResultList = true"
                      @blur="onSearchBlur"
                      @onStart="onSearchBlur"
                    >
                    </GoogleAutocomplete>
                  </div>
                  <div
                    v-if="showResultList && stores"
                    class="relative z-20 flex justify-center"
                  >
                    <StoreListResult
                      :items="stores"
                      :address="storeSearch.searchAddress"
                      :latLng="storeSearch.searchLatLng"
                      class="absolute top-0 w-[95%]"
                      :link="false"
                      @selected="onSelectedStore"
                    >
                    </StoreListResult>
                  </div>
                </div>

                <h3 class="text-2xl font-extralight text-primary">
                  {{ t('Enter your details') }}
                </h3>
                <form
                  class="grid grid-cols-1 gap-y-5 divide-y"
                  @submit.prevent="checkHasFiles"
                >
                  <USAEnquiryForm
                    v-if="state.isUSA || state.isCA"
                    :form="form"
                  >
                  </USAEnquiryForm>
                  <FREnquiryForm
                    v-else-if="state.isFR"
                    :form="form"
                  >
                  </FREnquiryForm>
                  <ZAEnquiryForm
                    v-else-if="state.isZA"
                    :form="form"
                  >
                  </ZAEnquiryForm>
                  <BaseEnquiryForm
                    v-else
                    :form="form"
                  >
                  </BaseEnquiryForm>
                  <div class="flex justify-end gap-x-5 pt-6">
                    <button
                      v-if="!form.processing"
                      class="rounded-md bg-white px-3 py-2 text-sm capitalize text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      @click.prevent="closeModal"
                    >
                      {{ t('cancel') }}
                    </button>
                    <button
                      type="submit"
                      class="rounded-md bg-bsPrimary px-3 py-2 text-sm capitalize text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#2a88bd] disabled:opacity-50"
                      :disabled="form.processing || dropzone.processing.value || !tribe?.slug"
                    >
                      <FontAwesomeIcon
                        v-if="form.processing || dropzone.processing.value"
                        class="mr-2 animate-spin-slow"
                        size="lg"
                        icon="fa-solid fa-spinner"
                      ></FontAwesomeIcon>
                      {{ t('submit') }}
                    </button>
                  </div>
                </form>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style scoped></style>
