<script setup lang="ts">
import BaseEnquiryForm from '@/ts/components/enquiries/form/dialog/BaseEnquiryForm.vue'
import type { EnquiryFormType } from '@/ts/api/enquiries'
import { toRefs } from 'vue'
import type { Form } from 'laravel-precognition-vue/dist/types'
import { useI18n } from 'vue-i18n'

const rootProps = defineProps<{
  form: EnquiryFormType & Form<EnquiryFormType>
}>()
const { form } = toRefs(rootProps)
const { t } = useI18n()
</script>

<template>
  <BaseEnquiryForm :form="form">
    <template v-slot:bottom="{ form }">
      <div class="col-span-2">
        <label>
          <input
            type="checkbox"
            class="text-primary focus:ring-0"
            @change="form.validate('RGPD')"
            v-model="form.RGPD"
          />
          {{ t('Click here to indicate you have read and agree to our') }}
          <a
            class="text-primary"
            href="/downloads/SignaramaSouthAfricaTerms.pdf"
            target="_blank"
          >
            {{ t('terms & conditions') }}
          </a>
        </label>
        <p
          v-if="form.errors.RGPD"
          class="my-2 text-sm text-red-400"
        >
          {{ form.errors.RGPD }}
        </p>
      </div>
    </template>
  </BaseEnquiryForm>
</template>

<style scoped></style>
