<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { ref, toRefs } from 'vue'
import { distance } from '@/ts/services/geoService'
import type { getBaseTribeInfoResult } from '@/ts/api/tribes'

const rootProps = withDefaults(
  defineProps<{
    items: getBaseTribeInfoResult[] | null
    address: string | null | undefined
    latLng: google.maps.LatLngLiteral | null | undefined
    link?: boolean
  }>(),
  {
    link: true
  }
)
const props = toRefs(rootProps)
const listElement = ref(null)
const emit = defineEmits(['selected'])

const { t } = useI18n()
const enableDistance = false
</script>

<template>
  <div
    ref="listElement"
    class="max-h-[400px] overflow-y-auto text-mainText"
  >
    <div class="border border-t-0 border-[#9f9999] bg-[#f0f0f0] p-4 text-base leading-4">
      {{ t('Closest stores to') }}
      <p>
        <strong>
          {{ props.address.value }}
        </strong>
      </p>
    </div>
    <div
      v-if="items && items.length > 0"
      class="flex flex-col divide-y divide-[#9f9999] border border-t-0 border-[#9f9999]"
    >
      <component
        :is="props.link.value ? 'RouterLink' : 'button'"
        v-for="item in props.items.value"
        :key="item.slug"
        class="flex flex-col bg-white p-4 text-left"
        :to="{ name: 'Welcome', params: { slug: item.slug } }"
        @click="emit('selected', item)"
      >
        <h4 class="font-semibold text-primary">
          {{ item.name }}
        </h4>
        <span
          v-if="enableDistance && latLng && item.latitude && item.longitude"
          class="text-xs text-black"
        >
          {{ distance(latLng, { lat: Number(item.latitude), lng: Number(item.longitude) }) }}
          {{ t('distance.unit away') }}
        </span>
        <div class="text-sm text-[#817373]">
          <p v-if="item.address_1">
            {{ item.address_1 }}
          </p>
          <p v-if="item.address_2">
            {{ item.address_2 }}
          </p>
          <p>{{ item.locality }} {{ item.postal_code }}</p>
        </div>
      </component>
    </div>
  </div>
</template>

<style scoped></style>
