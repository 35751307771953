import { type RouteRecordRaw } from 'vue-router'
import { getHasComponentsName } from '@/ts/routes/utils'
import { sharePages } from '@/ts/routes/sharePageRouter'
export const locationRoutes: RouteRecordRaw = {
  path: '',
  component: () => import('@/ts/pages/locations/LocationPage.vue'),
  children: [
    // Welcome
    {
      name: 'Welcome',
      path: '',
      component: () => import('@/ts/pages/locations/WelcomePage.vue')
    },
    // Reviews
    {
      name: 'Reviews',
      path: 'reviews',
      component: () => import('@/ts/pages/locations/ReviewsPage.vue')
    },
    // Artwork
    {
      name: 'Artwork',
      path: 'artwork',
      redirect: { name: 'Contact' },
      meta: {
        scrollToTop: false
      }
    },
    // Contact
    {
      name: 'Contact',
      path: 'contact',
      components: {
        default: () => import('@/ts/pages/contact/ContactPage.vue'),
        RightSide: () => import('@/ts/components/location/RightSideContact.vue')
      },
      meta: {
        hiddenContact: true,
        scrollToTop: false
      }
    },
    // products
    {
      name: 'Products',
      path: 'products',
      components: {
        default: () => import('@/ts/pages/products/ProductsPage.vue'),
        RightSide: () => import('@/ts/components/location/RightSideContact.vue')
      },
      meta: {
        scrollToTop: false
      }
    },
    // Gallery
    {
      name: 'Gallery',
      path: 'gallery',
      components: {
        default: () => import('@/ts/pages/gallery/GalleryPage.vue'),
        RightSide: () => import('@/ts/components/location/RightSideContact.vue')
      },
      meta: {
        scrollToTop: false
      }
    },
    // our-work
    {
      path: 'our-work',
      components: {
        default: () => import('@/ts/pages/ourWork/OurWorkPage.vue'),
        RightSide: () => import('@/ts/components/location/RightSideContact.vue')
      },
      children: [
        {
          name: 'OurWork',
          path: '',
          component: () => import('@/ts/pages/ourWork/OurWorkListsPage.vue'),
          meta: {
            scrollToTop: false
          }
        },
        {
          name: 'OurWorkDetail',
          path: ':ourWork_slug',
          component: () => import('@/ts/pages/ourWork/OurWorkDetailPage.vue'),
          meta: {
            scrollToTop: false
          }
        }
      ]
    },
    // team
    {
      name: 'Team',
      path: 'team',
      components: {
        default: () => import('@/ts/pages/team/TeamPage.vue'),
        RightSide: () => import('@/ts/components/location/RightSideContact.vue')
      },
      meta: {
        scrollToTop: false
      }
    }
  ]
}

export const hasRightSideBlock = () =>
  getHasComponentsName('RightSide', [...locationRoutes.children, ...sharePages])
