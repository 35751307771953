<script lang="ts" setup>
import { ref, toRefs, watch, reactive, toValue, computed, nextTick } from 'vue'
import type { Ref } from 'vue'
import TickIcon from './../TickIcon.vue'
import HoverImg from './../HoverImg.vue'
import IsHover from './../IsHover.vue'
import type { SetStepType } from './../IndustryType'

type ItemType = {
  image: string
  hoverImage: string
  title: string
}

type PropsType = {
  options?: ItemType[]
  industry?: string | null | Ref<string | null>
}

const props = withDefaults(defineProps<{ stepNum: number; propsData: PropsType }>(), {
  propsData: () => ({})
})

const emit = defineEmits<{
  (e: 'passValue', value: { key: string; value: string | null }): void
  (e: 'setStep', value: ThisParameterType<SetStepType>): ReturnType<SetStepType>
}>()

const items = reactive<ItemType[]>([])
const { propsData, stepNum } = toRefs(props)
const selectOption = ref(toValue(propsData.value.industry) || null)
const isLockSwitch = computed(() => selectOption.value === null)

defineExpose({ isLockSwitch })

watch(
  propsData,
  (val) => {
    if (val.options) {
      items.splice(0, items.length)
      items.push(...val.options)
    }
  },
  { immediate: true }
)

watch(selectOption, (val) => {
  emit('passValue', { key: 'industry', value: val })

  nextTick(() => {
    setTimeout(() => {
      emit('setStep', { step: stepNum.value + 1 })
    }, 1000)
  })
})
</script>

<template>
  <div>
    <slot name="header"></slot>
    <slot name="content">
      <div class="items-wrap">
        <div
          class="item"
          v-for="item in items"
          :key="item.title"
        >
          <IsHover v-slot="{ isHover }">
            <div
              class="content"
              :class="{ active: selectOption === item.title }"
              @click="selectOption = item.title"
            >
              <HoverImg
                :default-img="item.image"
                :hover-img="item.hoverImage"
                :alt-text="item.title"
                :lock="isHover || selectOption === item.title"
              ></HoverImg>
              <p>{{ item.title }}</p>
              <TickIcon
                class="tick"
                :isShow="selectOption === item.title"
              ></TickIcon>
            </div>
          </IsHover>
        </div>
      </div>
    </slot>
    <slot name="footer"></slot>
  </div>
</template>

<style lang="scss" scoped>
.items-wrap {
  @apply grid grid-cols-1 sm:grid-cols-2 md:grid-cols-[repeat(2,230px)] place-content-center gap-12 py-10;
  .item {
    .content {
      @apply flex flex-col items-center justify-center cursor-pointer text-xl gap-y-2 rounded-2xl px-1 pt-4 pb-1 relative;
      &:hover,
      &.active {
        box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
        @apply bg-white;
      }
    }
  }
}

.tick {
  @apply absolute top-10 right-20;
  @screen md {
    @apply absolute top-10 right-9;
  }
}
</style>
